.gwBSuY {
  width: 350px;
  flex-shrink: 0;
  padding-top: 20px;
}
@media (max-width: 1200px) and (min-width: 768px) {
  .gwBSuY {
    width: 260px;
  }
}
@media (max-width: 767px) {
  .gwBSuY {
    order: 0;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    position: relative !important;
  }
}
.kSbJGA {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}
@media (max-width: 990px) and (min-width: 768px) {
  .kSbJGA {
    padding-right: 15px;
  }
}
.dAPHLO {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #0d1136;
  text-align: center;
  margin-bottom: 40px;
}
.colDIV {
  display: flex;
  flex-direction: row;
}
.colDIV2 {
  display: flex;
  flex-direction: row;
}
.phoneLbl {
  font-size: 15px;
}
.subBtn {
  background-color: #009e7f;
  color: white;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin-top: 20px;
}
