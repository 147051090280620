/* бүтээгдэхүүн */
.cxyAZX {
    background-color: #fff;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #f3f3f3;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
/* .cxyAZX:hover{
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    transform: translateY(-5px);
} */
.bLdNZu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
    padding: 10px;
    height: 240px;
}
.ithsmr {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
}
@media screen and (max-width: 1280px){
    .bLdNZu {
        height: 200px;
    }
}
.kKpnwf {
    box-sizing: border-box;
    min-width: 0;
    margin: 0;
    padding: 10px 10px 20px;
}
.producttitle {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    margin: 0 0 7px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.productprices {
    margin-top: 10px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
}
.productpercentprice{
    text-align: center;
    display:flex;
    flex-direction: column;
}
.productpercent{
    font-size: 13px;
    font-weight: 400;
    color: #FFB369;
}
.productprice {
    font-size: 15px;
    font-weight: 700;
    color: #009E7F;
}
.productpointquantity{
    margin-top: 5px;
    justify-content: space-between;
    display:flex;
    vertical-align: bottom;
}
.productquantity {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #77798C;
    vertical-align: bottom;
    margin-top: auto;
    margin-bottom: 1px;
}
.productpoint {
    font-family: Lato,sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #009E7F;
    vertical-align: bottom;
    margin-top: auto;
}
.byhaCB {
    border: 1px solid #019376;
    border-radius: 15px;
    font-size: 13px;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: inherit;
    background-color: #fff;
    color: #019376;
    width: 50px;
}

.jNqNmP {
    font-family: Lato,sans-serif;
    margin: 0;
    font-size: 13px;
    font-weight: 700;
}
/* сагсанд сонгогдсон бүтээгдэхүүний хэлбэржүүлэлт */
.hgBplF {
    display: flex;
    background-color: #009E7F;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    border-radius: 200px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 50px;
    top: 0;
    right: 0;
}
.jJUnxI {
    border: none;
    background-color: transparent;
    color: white;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    padding: 7px;
    cursor: pointer;
    font-size: 8px;
}
.gSFvVa {
    font-family: Lato,sans-serif;
    pointer-events: none;
    font-size: 12px;
}
.colorred{
    color: red;
}
.black{
    color: #0D1136
}