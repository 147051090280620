/* webview item [start] */
.lihBKG {
    padding: 15px 20px;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
}
.hjcFuj {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
}
.hjcFuj span {
    font-weight: 400;
}
.iTdekY {
    font-family: Lato,sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #2e70fa;
    line-height: 1;
    background-color: rgba(46,112,250,0.1);
    padding: 10px 5px;
    border-radius: 6px;
}
.guZSJO {
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px;
}
.Bmugg {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #0D1136;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}
.Bmugg.price {
    font-weight: 700;
} 
.Bmugg:last-child {
    margin-bottom: 0;
}
.font11{
    font-size: 11px;
}
/* webview item end */

/* webview detai start */
.cJGveh {
    width: 100%;
    min-height: calc(100vh - 190px);
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid #f1f1f1;
}
@media only screen and (max-width: 1199px){
    .cJGveh {
        height: 657px;
    }
}
.jjWgRb {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
}
.bLiRNB {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-right: 1px solid #f1f1f1;
    padding: 20px;
}
.bLiRNB h3 {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 10px;
}
.fbmTwo {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #77798C;
    line-height: 1.5;
}
.bPJmao {
    width: 235px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 20px;
}
.cqjOZT {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #77798C;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.cFTfrZ {
    color: #0D1136;
}
.cqjOZT.grandTotal {
    font-weight: 700;
    color: #0D1136;
}
.cqjOZT:last-child {
    margin-bottom: 0;
}
.lbTChn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px 25px;
    border-bottom: 1px solid #f1f1f1;
}
.foKLRz {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.foKLRz:first-child .progressboxstyle {
    width: 50%;
    left: 50%;
}
.foKLRz:last-child .progressboxstyle{
    width: 50%;
}
.jYdTsb {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* margin-bottom: 15px; */
}
/* progress bar */
.flwWPp {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #009E7F;
    width: 36px;
    height: 36px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ffffff;
    z-index: 1;
    border: 1px dashed #009E7F;
}
.fRVGLf {
    width: 13px;
    height: 17px;
}
.fRVGLf i {
    width: 100%;
}
.wHIWv {
    width: 100%;
    height: 4px;
    background-color: #E6E6E6;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -2px;
}
.kNirdD {
    margin-top: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.fcCkVG {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    text-align: center;
    padding: 0 8px;
}
.checked {
    background-color: #009E7F;
    color: #ffffff;
}
.checked2 {
    background-color: #9c2917;
    color: #000;
}
.rctable {
    font-size: 12px;
    color: #666;
    line-height: 1.5;
    box-sizing: border-box;
    position: relative;
    overflow: auto;
    height: 350px;
}
.cAgIfa {
    border-collapse: collapse;
}
.rctable table {
    border-spacing: 0px;
    width: 100%;
}
.rctable th, .rctable td {
    padding: 0;
    position: relative;
    border: 1px solid red;
    border-top: 0;
    border-left: 0;
    transition: box-shadow 0.3s;
    padding: 16px 8px;
    box-sizing: border-box;
    white-space: normal;
    word-break: break-word;
}
.cAgIfa thead th:first-child {
    padding-left: 110px;
    text-align: left;
}
.cAgIfa thead th:last-child {
    text-align: right;
}
.cAgIfa thead th {
    padding: 8px 20px;
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #0D1136;
    border: none;
}
.rctable thead td, .rctable thead th {
    background: #f7f7f7;
    text-align: center;
}
.cAgIfa tr td {
    /* padding: 20px; */
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #0D1136;
    border-bottom: 0;
    border: none;
}
.lhXkXj {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.kONoVC {
    width: 75px;
    height: 75px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.kONoVC img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.iVTGVp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 15px;
    overflow: hidden;
}
.kIgOkd {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}
.bnnPwi {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #77798C;
    margin-bottom: 5px;
}
.kcpNyZ {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #009E7F;
}
.tdright{
    text-align: right;
}
.tdright p{
    margin: 0px;
}
.notorder{
    padding: 20px;
    color: red;
}
/* webview detail end */

/* mobile view start */
.fDCATW {
    display: none;
}
@media only screen and (max-width: 989px){
    .fDCATW {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}
.bGLdjy {
    width: 100%;
    padding: 0 20px 20px;
}
@media (max-width: 767px){
    .bGLdjy {
        padding: 0;
    }
}
.rccollapse {
    background-color: transparent;
    border-radius: 0;
    border: 0;
}
.rccollapse > .rccollapseitem {
    margin-bottom: 15px;
    background-color: #F7F7F7;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border: 1px solid transparent;
}
.bGLdjy .rccollapse > .rccollapseitem.rccollapseitemactive {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
}
.rccollapsecontentinactive {
    display: none;
}
.rccollapse > .rccollapseitem > .rccollapseheader {
    padding: 0;
    outline: 0;
    display: flex;
    align-items: center;
    line-height: 22px;
    color: #666;
    cursor: pointer;
}
.rccollapse > .rccollapseitem > .rccollapseheader .arrow {
    display: inline-block;
    content: '\20';
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 4px solid #666;
    vertical-align: middle;
    margin-right: 8px;
}
.hzdtju {
    width: 100%;
}
.rccollapse > .rccollapseitem .rccollapsecontent {
    padding: 0px;
}

.rccollapsecontent {
    overflow: hidden;
    color: #666;
    padding: 0 16px;
    background-color: #fff;
}
.rccollapse > .rccollapseitem .rccollapsecontent > .rccollapsecontentbox {
    box-sizing: border-box;
    padding: 0px;
    margin: 0;
}
.bmogfF {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}
.eUHqXZ {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.cwZtQI {
    border-bottom: 1px solid #f1f1f1;
    border-right: 0;
}
.fgcIfk {
    width: 100%;
}
@media (max-width: 767px){
    .hALMhu {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .foKLRz {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-bottom: 30px;
    }
    .jYdTsb {
        width: auto;
        margin-bottom: 0;
    }
    .foKLRz:first-child .progressboxstyle {
        width: 3px;
        height: 100%;
        top: 50%;
    }
    .foKLRz:last-child .progressboxstyle{
        width: 3px;
        height: 100%;
        top: -50%;
    }
    .kNirdD {
        margin-top: 0px;
        width: 100%;
        -webkit-align-items: flex-start;
        -webkit-box-align: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
        margin-left: 20px;
    }
    .fcCkVG {
        padding: 0;
    }
    .wHIWv {
        width: 3px;
        height: 200%;
        left: 50%;
        top: -50%;
        margin-top: 0;
        margin-left: -1px;
    }
}
@media (max-width: 600px){
    .rctable table{
        width: 560px;
    }
}
/* mobile view end */