.eTHeCj {
    background-color: #F7F7F7;
    position: relative;
    padding: 10px 0 60px 0;
    min-height: 100vh;
}
.fZqxml {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    padding: 60px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 767px){
    .fZqxml {
        padding: 50px 25px;
    }
}
@media (max-width: 990px){
    .fZqxml {
        padding: 50px 45px;
    }
}
@media (min-width: 991px){
    .fZqxml {
        width: 870px;
    }
}
.homebtn {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #77798C;
    padding: 5px 15px;
    height: 36px;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    position: absolute;
    top: 15px;
    right: 15px;
    -webkit-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;
}
@media (max-width: 767px){
    .home-btn {
        font-size: 13px;
        height: 34px;
        padding: 5px 12px;
    }
}
.eIRhpR {
    margin-bottom: 60px;
}
@media (max-width: 767px){
    .eIRhpR {
        margin-bottom: 30px;
    }
}
.QVyIh {
    font-family: Poppins,sans-serif;
    font-size: 21px;
    font-weight: 600;
    color: #0D1136;
    line-height: 1;
    margin-bottom: 32px;
}
@media (max-width: 767px){
    .QVyIh {
        font-size: calc(15px + 1px);
        margin-bottom: 25px;
    }
}
.bvSdvR {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.eIHWxM {
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 30px;
}
@media (max-width: 767px){
    .eIHWxM {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}
.gSskSI:first-child {
    padding-left: 0;
    border-left: 0;
}
.gSskSI {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0 15px;
    border-left: 1px solid #f1f1f1;
}
@media (max-width: 767px){
    .gSskSI {
        max-width: 100%;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
        padding: 0;
        border: 0;
    }
}
.gSskSI .title {
    margin-bottom: 10px;
}
.bHcUtG {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 700;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.bvSdvR:last-child {
    color: #424561;
}
.bvSdvR {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.dEojFW {
    margin-bottom: 60px;
}
@media (max-width: 767px){
    .dEojFW {
        margin-bottom: 50px;
    }
}
.hXfWwV {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
}
.jHaRgo {
    -webkit-flex-basis: 210px;
    -ms-flex-preferred-size: 210px;
    flex-basis: 210px;
    max-width: 210px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
}
@media (max-width: 767px){
    .jHaRgo {
        -webkit-flex-basis: 105px;
        -ms-flex-preferred-size: 105px;
        flex-basis: 105px;
        max-width: 105px;
    }
}
.jHaRgo:after {
    content: ':';
    position: absolute;
    top: -1px;
    right: -2px;
    line-height: 1;
}
.gADUYd {
    padding-left: 90px;
}
@media (max-width: 767px){
    .gADUYd {
        padding-left: 20px;
    }
}
.bvSdvR:last-child {
    color: #424561;
}
.bvSdvR {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.footerwarn{
    margin-bottom: 10px;
    padding: 10px 2px;
    border: 1px solid  #009e7f;
    border-radius: 5px;
}
