.mainDiv {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  width: 90%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.1rem;
  max-width: 400px;
  margin: 100px auto;
}
.errorMsg {
  margin-left: 10px;
  color: red;
}
.loginBtn {
  background-color: rgb(0, 158, 127);
  color: white;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.alertMessage
{
  color: #28a745;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}