.ModalBackdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  --opacity: 0.5;
  background-color: hsla(0,0%,0%,var(--opacity));
  /* overflow: hidden; */
  z-index: 100;
}

.CloseSpan{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    color: rgb(13, 17, 54);
    border: 0px;
    outline: 0px;
    box-shadow: none;
    border-radius: 50%;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 100000;
    cursor: pointer;
}

.ModalBody{
  opacity: 1;
  padding: 0px;
  max-width: calc(100% - 30px);
  height: auto;
  max-height: calc(100vh - 30px);
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.ModalTextArea{
  height: 100%;
  width: 100%;
}

.ModalObserver{
  left: 0px;
  right: auto;
}
.ModalObserver1{
  height: calc(100% + 1px);
  float: left;
}
.ModalObserver2{
  width: 1000%;
  height: 1000%;
  min-height: 1px;
  min-width: 1px;
}
.bEucZD {
  width: 1020px;
  max-width: 100%;
  height: 100%;
}
.dncRqP {
  background-color: rgb(255, 255, 255);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  align-items: stretch;
  min-height: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
}
.dHQHGm {
  width: 50%;
  max-width: 50%;
  padding: 30px 60px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
}
.dncRqP * {
  box-sizing: border-box;
}
.dapnPR {
  border: 1px solid rgb(241, 241, 241);
  border-radius: 6px;
  margin-right: 20px;
  padding: 0px;
  outline: none;
  width: 70px;
  height: auto;
  overflow: hidden;
}
.dapnPR.custom-dot--active {
  border: 2px solid rgb(0, 158, 127);
}
.dHQHGmImg {
  width: 100%;
  min-width: auto;
    height: auto;
    position: relative;
    margin: auto;
}
.SPnrf {
  width: 50%;
  max-width: 50%;
  border-left: 1px solid rgb(243, 243, 243);
}
.ixsiGC {
  padding: 50px;
}
.lfdRJ {
  width: 100%;
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.fHUdc{
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: rgb(13, 17, 54);
  line-height: 1.5;
}
.fHUdcH {
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: rgb(13, 17, 54);
  line-height: 1.5;
  display: flex;
}
.fHUdcH1 {
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: rgb(0, 158, 127);
  line-height: 1.5;
  display: flex;
  margin-left: 20%;
}
.lmanW {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgb(119, 121, 140);
}
.scroll{
  overflow: auto;
  max-height: 200px;
  padding: 5px;
}
.kOEjVM {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgb(66, 69, 97);
  line-height: 2;
  text-align: justify;
  margin-bottom: 0px;
}
.gIBFsL {
  margin-top: 30px;
}
.dyPuuD {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.dyPuuD:last-child {
  margin-bottom: 0px;
}
.AhjRa {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: rgb(13, 17, 54);
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: rgb(247, 247, 247);
  padding: 0px 15px;
  border-radius: 6px;
  cursor: pointer;
  height: 30px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.kKdNbW {
  display: flex;
  flex-direction: column;
}
.wGaXT {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-shrink: 0;
  margin: 0%, 10%, 0% 10%;
}
.CIPBX {
  font-family: Lato, sans-serif;
  font-size: calc(16px);
  font-weight: 700;
  color: rgb(0, 158, 127);
  margin: auto;
  left: 0;
}
.CIPBX1 {
  font-family: Lato, sans-serif;
  font-size: calc(16px);
  font-weight: 700;
  color:black;
  left: 0;
  margin: auto;
}
.wgEPt {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgb(255, 179, 105);
  font-style: italic;
  padding: 0px 5px;
  overflow: hidden;
  position: relative;
  margin-left: 10px;
}
.gurUMl {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}
.hAARBe {
  padding: 0px 30px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  border-radius: 6px;
  appearance: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  text-decoration: none;
  border: 0px;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 158, 127);
  height: 48px;
  padding-left: 30px;
  padding-right: 30px;
  width: 170px;
  font-family: Lato, sans-serif;
    margin: 0px;
}
.vfnVS {
  box-sizing: border-box;
  min-width: 0px;
  margin: 0px 8px 0px 0px;
  width: 14.4px;
  height: 12px;
}
.buttonSpan{
  font-family: Lato, sans-serif;
  margin: 0px;
}
.flexrow{
  display: flex;
  flex-direction: row;
}
.flexcolumn{
  display: flex;
  flex-direction: column;
}
.descritem{
  width: 98%;
  padding-left: 2%;
  
}
.descritemhead{
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
  /* border: 1px solid grey; */
}
.descritemdetail{
  padding:10px;
  background-color: rgba(200,200,200,0.1);
  border-radius: 20px;
  border: 2px solid #009e7f;
}
.arrow{
  font-size: 10px;
}

@media(max-width: 767px) { 
  .scroll{
    padding: 5px;
    overflow: hidden;
    max-height: 100%;
    width: 80%;
  }
  .CloseSpan{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    color: rgb(13, 17, 54);
    border: 0px;
    outline: 0px;
    box-shadow: none;
    border-radius: 50%;
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
  .ModalBackdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    --opacity: 0.5;
    background-color: hsla(0,0%,0%,var(--opacity));
  }
  .ModalBody{
    transform: translateY(-30%) translateY(0px) translateX(-50%);
    position: absolute;
    bottom: 0px;
    left: 50%;
    padding: 0px;
    width: calc(100% + 1px);
    height: 100%;
    max-height: 100vh;
    max-width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 20px 20px 0px 0px;
    z-index: 99999;
  }
  .dHQHGm img {
    min-width: auto !important;
    width: 100%;
  }
  .dncRqP {
    /* background-color: rgb(255, 255, 255); */
    position: relative;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    min-height: 100%;
    border-radius: 6px;
    overflow: hidden;
    box-sizing: border-box;
  }
  .marginvh{
    margin-bottom: 20vh;
  }
  .bEucZD {
    width: 1020px;
    max-width: 100%;
    height: 100%;
    overflow: auto;
  }
  .dHQHGm {
    width: 100%;
    max-width: 100%;
    /* padding: 20px 0px; */
    /* padding: 15px 30px; */
    order: 0;
    /* margin-left: -3%; */
  }
  .SPnrf {
    width: 100%;
    max-width: 100%;
    order: 1;
    border: 0px;
  }
  .ixsiGC {
    padding: 0px;
  }
  .lfdRJ {
    width: 100%;
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }

  .fHUdcH {
    font-family: Poppins, sans-serif;
    font-size: 17px;
    font-weight: 600;
    color: rgb(13, 17, 54);
    line-height: 1.5;
    display: flex;
    margin-left: 15%;
  }
  .fHUdcH1 {
    font-family: Poppins, sans-serif;
    font-size: 17px;
    font-weight: 600;
    color: rgb(0, 158, 127);
    line-height: 1.5;
    display: flex;
    margin-right: 15%;
  }
  .lmanW {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(119, 121, 140);
  }
  .gIBFsL {
    margin-top: 30px;
  }
  .dyPuuD {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .wGaXT {
    margin-left: 25px;
    margin: 0% 10% 0% 10%;
  }
  .CIPBX {
    font-family: Lato, sans-serif;
    font-size: calc(16px);
    font-weight: 700;
    color: rgb(0, 158, 127);
    margin: auto;
    left: 0;
  }
  .CIPBX1 {
    font-family: Lato, sans-serif;
    font-size: calc(16px);
    font-weight: 700;
    color: black;
    margin: auto;
    left: 0;
  }
  .kKdNbW {
    margin-bottom: 50px;
  }
}
