/* Сагсны бие */
.lamVFu{
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    box-sizing: border-box;
    font-family: Lato,sans-serif;
    margin: 0;
    width: 420px;
    height: 90vh;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    z-index: 100;
    box-shadow: 0 21px 36px rgba(0,0,0,0.16);
    transition: all 0.1s ease-in-out;
    /* display: flex; */
    right: 0;
}
.jKfBPg{
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    box-sizing: content-box;
    height: 100%;
    width: 100%;
}
.gkbJjA{
    margin: 0;
    padding: 5px 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    justify-content: space-between;
}
.fhKndd{
    box-sizing: border-box;
    margin: 0;
    display: inline-flex;
    align-items: center;
    color: #009E7F;
}
.fhKndd span {
    font-weight: 700;
    color: #009E7F;
    padding-left: 10px;
}
.black{
    font-weight: 700;
    color: #0D1136;
}
.red{
    font-weight: 700;
    color: red;
}
.kWBUWu{
    box-sizing: border-box;
    margin: 0;
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    outline: 0;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: rgba(0,0,0,0.5);
    top: auto;
    position: relative;
    background-color: transparent;
}
.kWBUWu:hover{
    color: #ea4d4a;
}
.cartScrollbar{
    box-sizing: border-box;
    margin: 0;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    overflow: auto !important;
    height: 100%;
    max-height: calc(100vh - 245px);
}
.ospadding {
    box-sizing: inherit;
    direction: inherit;
    position: absolute;
    overflow: visible;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
}
.iKtYkh {
    width: 100%;
    height: auto;
}
.bhHvDf {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    flex-shrink: 0;
}
.dQOcYU {
    height: 48px;
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(0, 158, 127);
    padding: 0px;
    border-radius: 48px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    border: 0px;
    outline: 0px;
    cursor: pointer;
    margin-bottom: 15px;
    margin-left: 15px;
}
/* @media(max-width: 767px){
    .dQOcYU {
        height: 45px;
    }
} */
.dQOcYU > a {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 30px;
}
.flexcolumn{
    display: flex;
    flex-direction: column;
    margin: auto;
}
.kVQlLW {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    padding-left: 20px;
    padding-right: 10px;
}
.kVQlLW:hover {
    color: #fff;
}
.iDSbKK {
    height: 46px;
    width: auto;
    padding: 0 20px;
    border-radius: 28px;
    background-color: #ffffff;
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #009E7F;
    margin: 0px;
    margin-right: 1px;
}
.iDSbKKspan{
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: #009E7F;
    font-size: 12px;
    margin: auto;
}
.productpercent {
    font-size: 10px;
    font-weight: 400;
    color: #FFB369;
}
@media(max-width: 575px) { 
    .lamVFu{
        /* display: none; */
        /* position:absolute; */
        bottom:0;
        margin:0;
        width:100%;
        height: 60vh;
    }
    .cartScrollbar{
        /* max-height: 330px;
        height: 60%; */
    }
}