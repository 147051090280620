.dHQHGmImg {
    width: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* 
.item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
} */

@media (max-width: 600px) {
    .dHQHGmImg {
        width: 100%;
        height:100%;;
        text-align: center;
        align-content: center;
        text-align: center;
        align-items: center;
    }
}