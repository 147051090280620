@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback);
@import url(https://fonts.googleapis.com/css?family=Merriweather+Sans);
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.style_errorMsg__1Ynbg{
    color: red;
}
.style_loader__134eS {
color: #1ad15a;
font-size: 60px;
text-indent: -9999em;
overflow: hidden;
width: 1em;
height: 1em;
border-radius: 50%;
margin: 20% auto;
position: relative;
transform: translateZ(0);
animation: style_load6__3KhB7 1.7s infinite ease, style_round__1W6kO 1.7s infinite ease;
}
@keyframes style_load6__3KhB7 {
0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
5%,
95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
10%,
59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
}
20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
}
38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
}
100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
}
@keyframes style_round__1W6kO {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
} 
.style_CardBodyImg__2IMDp{
    height: 150px;
    transition: transform .2s ease-in-out;
    /* transform: scale(1.4); */
    /* transform-origin: 100% 0; */
    align-items: center;
}
.style_CardBodyImg__2IMDp:hover {
    transform: scale(2.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.style_CardBodyImgTd__1l8B4{
    width: 20%;
    text-align: center;
}

.style_CardBodyEdit__1of06{
    position: relative;
    width: 3%;
    text-align: center;
    align-items: center;
}

.style_CardIconCenter__7tlWe {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}


.style_loader__1t1b0 {
  color: #1ad15a;
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20% auto;
  position: relative;
  transform: translateZ(0);
  animation: style_load6__3u6-4 1.7s infinite ease, style_round__mFEnY 1.7s infinite ease;
}
@keyframes style_load6__3u6-4 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes style_round__mFEnY {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.style_loader__3sYZK {
    color: #1ad15a;
    font-size: 60px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    transform: translateZ(0);
    animation: style_load6__3CR1c 1.7s infinite ease, style_round__nn-_r 1.7s infinite ease;
  }
  @keyframes style_load6__3CR1c {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_round__nn-_r {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.style_CardBodyImg__3KAyd{
    height: 80px;
    transition: transform .2s ease-in-out;
    /* transform: scale(1.4); */
    /* transform-origin: 100% 0; */
    align-items: center;
}
.style_CardBodyImg__3KAyd:hover {
    transform: scale(2.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.style_CardBodyImgTd__2K2Sl{
    width: 10%;
    text-align: center;
}

.style_CardBodyEdit__3obmc{
    position: relative;
    width: 3%;
    text-align: center;
    align-items: center;
}

.style_CardIconCenter__13YON {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}


.style_loader__3rk2R {
  color: #1ad15a;
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20% auto;
  position: relative;
  transform: translateZ(0);
  animation: style_load6__3p29f 1.7s infinite ease, style_round__2RiKc 1.7s infinite ease;
}
@keyframes style_load6__3p29f {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes style_round__2RiKc {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.style_loader__15uvF {
    color: #1ad15a;
    font-size: 60px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    transform: translateZ(0);
    animation: style_load6__utE-V 1.7s infinite ease, style_round__3wZsm 1.7s infinite ease;
  }
  @keyframes style_load6__utE-V {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_round__3wZsm {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.style_marginRight__3m0Bz {
    margin: 0px 10px 0px 0px;
  }
  .style_errorMsg___Yv7M {
    margin-left: 10px;
    color: red;
  }
  
  .style_inputImage__Er2v8 {
    height: 50px;
  }
  .style_productPrice__3Qvcx {
    display: flex;
    flex-direction: row;
  }
  .style_productPrice__3Qvcx input {
    width: 200px;
  }
  
.style_CardBodyEdit__Qpc49 {
  position: relative;
  width: 3%;
  text-align: center;
  align-items: center;
}

.style_card-body__2tzfD {
  align-items: center;
}

.style_CardIconCenter__3eoTd {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.style_tdHarahCenter__36XRD {
  text-align: center;
}
.style_dropDownCenter__1Ul1R {
  text-align: center;
}
.style_dropdown-menu__CTDOz {
  cursor: hand;
}
.style_dropdown-item__2GJRk {
  cursor: pointer;
}
.style_btnHidden__dr3VY {
  display: none;
  margin-left: 10px;
  border-radius: 6px;
  background-color: transparent;
  outline: none;
  background-repeat: no-repeat;
  border: solid 2px green;
  border-radius: 30px;
}
.style_divBtnHide__2vjCn {
  display: flex;
  flex-direction: row;
}
.style_btnHidden__dr3VY i {
  color: darkgreen;
  font-size: 14px;
}
.style_selectCss__13xMT {
  font-size: 18px;
  background-color: blue;
}
.style_loader__k4cv0 {
  color: #1ad15a;
  font-size: 20px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 1% auto;
  position: relative;
  transform: translateZ(0);
  animation: style_load6__3n-ZE 1.7s infinite ease, style_round__1Ur8L 1.7s infinite ease;
}
@keyframes style_load6__3n-ZE {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes style_round__1Ur8L {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.style_textuud__3wB_p {
  text-align: center;
}
.style_tableScroll__2dZwy {
  overflow: auto;
  max-height:2600;
}

.boldStyle {
  font-weight: bold;
  font-size: 20px;
}

@media screen {
  #printSection {
    display: none;
  }
  .modal-lg {
    width: 500px;
  }
  #spanDate {
    visibility: hidden;
  }
  #labelDate {
    visibility: hidden;
  }
}

@media print {
  #printSection:after {
    content: "©  " attr(data-date) " Be Smart";
    font-size: 60px;
    font-weight: bold;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  body * {
    font-family: Arial, Helvetica, sans-serif;
    height: auto;
    visibility: hidden;
  }
  .modal-lg {
    max-width: 80% !important;
  }
  .badge {
    font-size: 60px;
  }
  #closeModal {
    visibility: hidden;
    display: none;
  }
  #printModal {
    display: none;
    visibility: hidden;
  }
  th {
    font-size: 60px;
    font-weight: bold;
  }
  td {
    inline-size: 150px;
    overflow-wrap: break-word;
    font-size: 60px;
    font-weight: bold;
  }
  label {
    font-size: 60px;
    font-weight: bold;
  }
  #spanDate {
    visibility: visible;
  }
  #labelDate {
    visibility: visible;
  }
  .boldStyle {
    font-weight: bold;
    font-size: 60px;
  }
  #printSection,
  #printSection * {
    visibility: visible;
  }
  #orderSection {
    display: none;
    visibility: hidden;
  }
}

.style_marginRight__2zWY_ {
  margin: 0px 10px 0px 0px;
}
.style_errorMsg__3Opa7 {
  margin-left: 10px;
  color: red;
}

.style_inputImage__2omDB {
  height: 50px;
}
.style_productPrice__Y7vWd {
  display: flex;
  flex-direction: row;
}
.style_productPrice__Y7vWd input {
  width: 200px;
}

.style_CardBodyImg__3_9Bw {
  width: 30px;
  height: auto;
  transition: transform 0.2s ease-in-out;
  /* transform: scale(1.4); */
  /* transform-origin: 100% 0; */
  align-items: center;
}
.style_CardBodyImg__3_9Bw:hover {
  transform: scale(
    2.5
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.style_CardBodyImgTd__3JDuc {
  width: 10%;
  text-align: center;
}

.style_CardBodyEdit__3vdf5 {
  position: relative;
  width: 3%;
  text-align: center;
  align-items: center;
}

.style_CardIconCenter__mHANq {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.style_loader__2jihp {
  color: #1ad15a;
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20% auto;
  position: relative;
  transform: translateZ(0);
  animation: style_load6__2w-qG 1.7s infinite ease, style_round__3GYs8 1.7s infinite ease;
}
@keyframes style_load6__2w-qG {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes style_round__3GYs8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.style_marginRight__2IFZH {
  margin: 0px 10px 0px 0px;
}
.style_errorMsg__3DLG9 {
  margin-left: 10px;
  color: red;
}

.style_inputImage__3lO5T {
  height: 50px;
}

.style_btnHidden__2Mxe0 {
  display: none;
  margin-left: 10px;
  border-radius: 6px;
  background-color: transparent;
  outline: none;
  background-repeat: no-repeat;
  border: solid 2px green;
  border-radius: 30px;
}
.style_divBtnHide__jgRsl {
  display: flex;
  flex-direction: row;
}
.style_btnHidden__2Mxe0 i {
  color: darkgreen;
  font-size: 14px;
}
.style_colorred__1fa_q{
  font-weight: bold;
  color: #CC0000;
}
.style_colorgreen__DUGk7{
  font-weight: bold;
  color: #00CC00;
}
.style_btnHidden__36_G2 {
  display: none;
  margin-left: 10px;
  border-radius: 6px;
  background-color: transparent;
  outline: none;
  background-repeat: no-repeat;
  border: solid 2px green;
  border-radius: 30px;
}
.style_divBtnHide__2zDZW {
  display: flex;
  flex-direction: row;
}
.style_btnHidden__36_G2 i {
  color: darkgreen;
  font-size: 14px;
}
.style_colorred__2h5tY{
  font-weight: bold;
  color: #CC0000;
}
.style_colorgreen__3amXM{
  font-weight: bold;
  color: #00CC00;
}
.style_errorMsg__2mZfK {
    color: red;
}

.style_loader__33HWY {
    color: #1ad15a;
    font-size: 60px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    transform: translateZ(0);
    animation: style_load6__2ZPvJ 1.7s infinite ease, style_round__283uY 1.7s infinite ease;
}

@keyframes style_load6__2ZPvJ {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%, 95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%, 59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes style_round__283uY {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.style_inputImage__1CV2z {
    height: 50px;
}
button:focus {outline:0;}
::-webkit-scrollbar {
    width: 2px;
    height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.style_jVFuuY__P7OH0 {
    
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #009e7f;
    padding: 0;
    border-radius: 6px 0 0 6px;
    box-shadow: 0 21px 36px rgba(0, 0, 0, 0.16);
    border: 0;
    outline: 0;
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 50%;
    margin-top: -46px;
    z-index: 99;
}
.style_KDupa__1yeDO {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    padding-left: 5px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 10px;
}
.style_KDupa__1yeDO i {
    margin-right: 10px;
}
.style_dCrbXJ__7odH6 {
    width: auto;
    height: 35px;
    min-width: 80px;
    overflow: hidden;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #009e7f;
    margin: 0 10px 10px;
}
@media(max-width: 575px) { 
    .style_jVFuuY__P7OH0 {
        top: auto;
        flex-direction: row;
        box-sizing: border-box;
        font-family: Lato, sans-serif;
        margin: 0;
        -webkit-appearance: none;
                appearance: none;
        -webkit-box-align: center;
        border-radius: 60px;
        width: calc(100% - 60px);
        height: 45px;
        padding: 2px 2px 2px 30px;
        bottom: 30px;
        right: 30px;
    }
    .style_KDupa__1yeDO{
        cursor: pointer;
        box-sizing: border-box;
        margin: 0;
        font-family: Lato,sans-serif;
        font-size: 12px;
        padding: 0px;
    }
    .style_dCrbXJ__7odH6{
        cursor: pointer;
        box-sizing: border-box;
        margin: 0;
        border-radius: 28px;
        font-family: Lato,sans-serif;
        font-size: 12px;
        width: 90px;
        height: 41px;
        margin-left: auto;
        margin-right: 0;
    }
}

.style_bOpSCc__1zOy- {
    font-size: 15px;
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom: 1px solid;
    border-bottom-color: #F7F7F7;
    display: flex;
    align-items: center;
}
.style_bkLFHu__20mKF {
    display: flex;
    font-size: 15px;
    font-weight: 700;
    border-radius: 200px;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    width: 30px;
    height: 90px;
    flex-direction: column-reverse;
    background-color: #F7F7F7;
    color: #0D1136;
}
.style_cbNtye__aqcVN {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
    cursor: pointer;
    color: #77798C;
}
.style_kyNexk__2cevw {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin: 0 5px;
}
.style_djMkq__2q0NQ {
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 0px;
    line-height: 1.5;
    text-align: center;
}
.style_ewWbZD__1PwoV {
    color: #009E7F;
    font-size: 12px;
}
.style_ewWbZD2__2BF4Q {
    color: #0D1136;
    font-size: 14px;
}
.style_gKpPoA__3j2GP {
    font-size: 13px;
    font-weight: 400;
    color: #77798C;
    margin-bottom: 5px;
}
.style_cbwArM__2WWQa {
    color: #009E7F;
    margin-left: auto;
    font-size: 12px;
}
.style_cbwArM2__3kXey {
    color: #009E7F;
    margin-left: auto;
    font-size: 14px;
}
.style_ijpSFz__2DygM {
    padding: 0px;
    border: 0;
    outline: 0;
    margin-left: 5px;
    cursor: pointer;
    color: rgba(0,0,0,0.25);
    transition: all 0.4s ease;
    background-color: transparent;
}
.style_ijpSFz__2DygM:hover {
    color: #ea4d4a;
}
.style_flexcolumn__3Z943{
    display: flex;
    flex-direction: column;
    margin: auto;
}
.style_flexrow__3jezn{
    display: flex;
    flex-direction: row;
    margin: auto;
}
.style_margintop2__3yvFp{
    margin-top: 2px;
}
.style_fFQivv__1p-9o {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
}
.style_productpercent__1XRRf {
    font-size: 10px;
    font-weight: 400;
    color: #FFB369;
}
.style_colorred__1Lt7V{
    color: red;
}
/* Сагсны бие */
.style_lamVFu__2wg7e{
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    box-sizing: border-box;
    font-family: Lato,sans-serif;
    margin: 0;
    width: 420px;
    height: 90vh;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    z-index: 100;
    box-shadow: 0 21px 36px rgba(0,0,0,0.16);
    transition: all 0.1s ease-in-out;
    /* display: flex; */
    right: 0;
}
.style_jKfBPg__3UXWD{
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    box-sizing: content-box;
    height: 100%;
    width: 100%;
}
.style_gkbJjA__2GsrR{
    margin: 0;
    padding: 5px 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    justify-content: space-between;
}
.style_fhKndd__3uxOw{
    box-sizing: border-box;
    margin: 0;
    display: inline-flex;
    align-items: center;
    color: #009E7F;
}
.style_fhKndd__3uxOw span {
    font-weight: 700;
    color: #009E7F;
    padding-left: 10px;
}
.style_black__hqIFq{
    font-weight: 700;
    color: #0D1136;
}
.style_red__2zZjj{
    font-weight: 700;
    color: red;
}
.style_kWBUWu__RAhdj{
    box-sizing: border-box;
    margin: 0;
    -webkit-appearance: none;
            appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    outline: 0;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: rgba(0,0,0,0.5);
    top: auto;
    position: relative;
    background-color: transparent;
}
.style_kWBUWu__RAhdj:hover{
    color: #ea4d4a;
}
.style_cartScrollbar__DgOiW{
    box-sizing: border-box;
    margin: 0;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    overflow: auto !important;
    height: 100%;
    max-height: calc(100vh - 245px);
}
.style_ospadding__2ARAI {
    box-sizing: inherit;
    direction: inherit;
    position: absolute;
    overflow: visible;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
}
.style_iKtYkh__33CvP {
    width: 100%;
    height: auto;
}
.style_bhHvDf__RrvwE {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    flex-shrink: 0;
}
.style_dQOcYU__sRsRU {
    height: 48px;
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(0, 158, 127);
    padding: 0px;
    border-radius: 48px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    border: 0px;
    outline: 0px;
    cursor: pointer;
    margin-bottom: 15px;
    margin-left: 15px;
}
/* @media(max-width: 767px){
    .dQOcYU {
        height: 45px;
    }
} */
.style_dQOcYU__sRsRU > a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
}
.style_flexcolumn__hCW-j{
    display: flex;
    flex-direction: column;
    margin: auto;
}
.style_kVQlLW__q-4If {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
    padding-left: 20px;
    padding-right: 10px;
}
.style_kVQlLW__q-4If:hover {
    color: #fff;
}
.style_iDSbKK__1cVgQ {
    height: 46px;
    width: auto;
    padding: 0 20px;
    border-radius: 28px;
    background-color: #ffffff;
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #009E7F;
    margin: 0px;
    margin-right: 1px;
}
.style_iDSbKKspan__2jKfD{
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: #009E7F;
    font-size: 12px;
    margin: auto;
}
.style_productpercent__3HNRr {
    font-size: 10px;
    font-weight: 400;
    color: #FFB369;
}
@media(max-width: 575px) { 
    .style_lamVFu__2wg7e{
        /* display: none; */
        /* position:absolute; */
        bottom:0;
        margin:0;
        width:100%;
        height: 60vh;
    }
    .style_cartScrollbar__DgOiW{
        /* max-height: 330px;
        height: 60%; */
    }
}
.style_ModalBackdrop__3rFa4 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  --opacity: 0.5;
  background-color: hsla(0,0%,0%,var(--opacity));
  /* overflow: hidden; */
  z-index: 100;
}

.style_CloseSpan__1SiOk{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    color: rgb(13, 17, 54);
    border: 0px;
    outline: 0px;
    box-shadow: none;
    border-radius: 50%;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 100000;
    cursor: pointer;
}

.style_ModalBody__o4atS{
  opacity: 1;
  padding: 0px;
  max-width: calc(100% - 30px);
  height: auto;
  max-height: calc(100vh - 30px);
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.style_ModalTextArea__3hAHb{
  height: 100%;
  width: 100%;
}

.style_ModalObserver__3IcTt{
  left: 0px;
  right: auto;
}
.style_ModalObserver1__3-_3V{
  height: calc(100% + 1px);
  float: left;
}
.style_ModalObserver2__1DvMW{
  width: 1000%;
  height: 1000%;
  min-height: 1px;
  min-width: 1px;
}
.style_bEucZD__1Sh8i {
  width: 1020px;
  max-width: 100%;
  height: 100%;
}
.style_dncRqP__GeJ9N {
  background-color: rgb(255, 255, 255);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
}
.style_dHQHGm__1SrCQ {
  width: 50%;
  max-width: 50%;
  padding: 30px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.style_dncRqP__GeJ9N * {
  box-sizing: border-box;
}
.style_dapnPR__3ZBTz {
  border: 1px solid rgb(241, 241, 241);
  border-radius: 6px;
  margin-right: 20px;
  padding: 0px;
  outline: none;
  width: 70px;
  height: auto;
  overflow: hidden;
}
.style_dapnPR__3ZBTz.style_custom-dot--active__b0o6G {
  border: 2px solid rgb(0, 158, 127);
}
.style_dHQHGmImg__2VcVR {
  width: 100%;
  min-width: auto;
    height: auto;
    position: relative;
    margin: auto;
}
.style_SPnrf__zCYds {
  width: 50%;
  max-width: 50%;
  border-left: 1px solid rgb(243, 243, 243);
}
.style_ixsiGC__25e-b {
  padding: 50px;
}
.style_lfdRJ__35Rpz {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.style_fHUdc__3ee3i{
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: rgb(13, 17, 54);
  line-height: 1.5;
}
.style_fHUdcH__3cw1C {
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: rgb(13, 17, 54);
  line-height: 1.5;
  display: flex;
}
.style_fHUdcH1__G4-9H {
  font-family: Poppins, sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: rgb(0, 158, 127);
  line-height: 1.5;
  display: flex;
  margin-left: 20%;
}
.style_lmanW__pozpH {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgb(119, 121, 140);
}
.style_scroll__18juf{
  overflow: auto;
  max-height: 200px;
  padding: 5px;
}
.style_kOEjVM__LFTnu {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgb(66, 69, 97);
  line-height: 2;
  text-align: justify;
  margin-bottom: 0px;
}
.style_gIBFsL__1ugqo {
  margin-top: 30px;
}
.style_dyPuuD__1al4r {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}
.style_dyPuuD__1al4r:last-child {
  margin-bottom: 0px;
}
.style_AhjRa__3PXEA {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: rgb(13, 17, 54);
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: rgb(247, 247, 247);
  padding: 0px 15px;
  border-radius: 6px;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_kKdNbW__zt0Ua {
  display: flex;
  flex-direction: column;
}
.style_wGaXT__RpY2M {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: 0%, 10%, 0% 10%;
}
.style_CIPBX__1FSRF {
  font-family: Lato, sans-serif;
  font-size: calc(16px);
  font-weight: 700;
  color: rgb(0, 158, 127);
  margin: auto;
  left: 0;
}
.style_CIPBX1__3rYyJ {
  font-family: Lato, sans-serif;
  font-size: calc(16px);
  font-weight: 700;
  color:black;
  left: 0;
  margin: auto;
}
.style_wgEPt__3eJk_ {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgb(255, 179, 105);
  font-style: italic;
  padding: 0px 5px;
  overflow: hidden;
  position: relative;
  margin-left: 10px;
}
.style_gurUMl__2NAoY {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}
.style_hAARBe__HI5Mn {
  padding: 0px 30px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  border-radius: 6px;
  -webkit-appearance: none;
          appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  text-decoration: none;
  border: 0px;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 158, 127);
  height: 48px;
  padding-left: 30px;
  padding-right: 30px;
  width: 170px;
  font-family: Lato, sans-serif;
    margin: 0px;
}
.style_vfnVS__2fz5N {
  box-sizing: border-box;
  min-width: 0px;
  margin: 0px 8px 0px 0px;
  width: 14.4px;
  height: 12px;
}
.style_buttonSpan__h38O0{
  font-family: Lato, sans-serif;
  margin: 0px;
}
.style_flexrow__17s1C{
  display: flex;
  flex-direction: row;
}
.style_flexcolumn__3Y_AD{
  display: flex;
  flex-direction: column;
}
.style_descritem__26obz{
  width: 98%;
  padding-left: 2%;
  
}
.style_descritemhead__1p33w{
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
  /* border: 1px solid grey; */
}
.style_descritemdetail__a-ql5{
  padding:10px;
  background-color: rgba(200,200,200,0.1);
  border-radius: 20px;
  border: 2px solid #009e7f;
}
.style_arrow__I7aa1{
  font-size: 10px;
}

@media(max-width: 767px) { 
  .style_scroll__18juf{
    padding: 5px;
    overflow: hidden;
    max-height: 100%;
    width: 80%;
  }
  .style_CloseSpan__1SiOk{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    color: rgb(13, 17, 54);
    border: 0px;
    outline: 0px;
    box-shadow: none;
    border-radius: 50%;
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
  .style_ModalBackdrop__3rFa4 {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    --opacity: 0.5;
    background-color: hsla(0,0%,0%,var(--opacity));
  }
  .style_ModalBody__o4atS{
    transform: translateY(-30%) translateY(0px) translateX(-50%);
    position: absolute;
    bottom: 0px;
    left: 50%;
    padding: 0px;
    width: calc(100% + 1px);
    height: 100%;
    max-height: 100vh;
    max-width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 20px 20px 0px 0px;
    z-index: 99999;
  }
  .style_dHQHGm__1SrCQ img {
    min-width: auto !important;
    width: 100%;
  }
  .style_dncRqP__GeJ9N {
    /* background-color: rgb(255, 255, 255); */
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    min-height: 100%;
    border-radius: 6px;
    overflow: hidden;
    box-sizing: border-box;
  }
  .style_marginvh__-IbnN{
    margin-bottom: 20vh;
  }
  .style_bEucZD__1Sh8i {
    width: 1020px;
    max-width: 100%;
    height: 100%;
    overflow: auto;
  }
  .style_dHQHGm__1SrCQ {
    width: 100%;
    max-width: 100%;
    /* padding: 20px 0px; */
    /* padding: 15px 30px; */
    order: 0;
    /* margin-left: -3%; */
  }
  .style_SPnrf__zCYds {
    width: 100%;
    max-width: 100%;
    order: 1;
    border: 0px;
  }
  .style_ixsiGC__25e-b {
    padding: 0px;
  }
  .style_lfdRJ__35Rpz {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .style_fHUdcH__3cw1C {
    font-family: Poppins, sans-serif;
    font-size: 17px;
    font-weight: 600;
    color: rgb(13, 17, 54);
    line-height: 1.5;
    display: flex;
    margin-left: 15%;
  }
  .style_fHUdcH1__G4-9H {
    font-family: Poppins, sans-serif;
    font-size: 17px;
    font-weight: 600;
    color: rgb(0, 158, 127);
    line-height: 1.5;
    display: flex;
    margin-right: 15%;
  }
  .style_lmanW__pozpH {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(119, 121, 140);
  }
  .style_gIBFsL__1ugqo {
    margin-top: 30px;
  }
  .style_dyPuuD__1al4r {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
  }
  .style_wGaXT__RpY2M {
    margin-left: 25px;
    margin: 0% 10% 0% 10%;
  }
  .style_CIPBX__1FSRF {
    font-family: Lato, sans-serif;
    font-size: calc(16px);
    font-weight: 700;
    color: rgb(0, 158, 127);
    margin: auto;
    left: 0;
  }
  .style_CIPBX1__3rYyJ {
    font-family: Lato, sans-serif;
    font-size: calc(16px);
    font-weight: 700;
    color: black;
    margin: auto;
    left: 0;
  }
  .style_kKdNbW__zt0Ua {
    margin-bottom: 50px;
  }
}

/* бүтээгдэхүүн */
.style_cxyAZX__2_buf {
    background-color: #fff;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #f3f3f3;
    display: flex;
    flex-direction: column;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
/* .cxyAZX:hover{
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    transform: translateY(-5px);
} */
.style_bLdNZu__2LnDl {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
    padding: 10px;
    height: 240px;
}
.style_ithsmr__1mxc8 {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
}
@media screen and (max-width: 1280px){
    .style_bLdNZu__2LnDl {
        height: 200px;
    }
}
.style_kKpnwf__Ca66i {
    box-sizing: border-box;
    min-width: 0;
    margin: 0;
    padding: 10px 10px 20px;
}
.style_producttitle__3YYBQ {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    margin: 0 0 7px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.style_productprices__267Bt {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.style_productpercentprice__TodMH{
    text-align: center;
    display:flex;
    flex-direction: column;
}
.style_productpercent__3vIFJ{
    font-size: 13px;
    font-weight: 400;
    color: #FFB369;
}
.style_productprice__36136 {
    font-size: 15px;
    font-weight: 700;
    color: #009E7F;
}
.style_productpointquantity__1a3rb{
    margin-top: 5px;
    justify-content: space-between;
    display:flex;
    vertical-align: bottom;
}
.style_productquantity__5tj8- {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #77798C;
    vertical-align: bottom;
    margin-top: auto;
    margin-bottom: 1px;
}
.style_productpoint__-0CzT {
    font-family: Lato,sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #009E7F;
    vertical-align: bottom;
    margin-top: auto;
}
.style_byhaCB__3w8O7 {
    border: 1px solid #019376;
    border-radius: 15px;
    font-size: 13px;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;
    -webkit-appearance: none;
            appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: inherit;
    background-color: #fff;
    color: #019376;
    width: 50px;
}

.style_jNqNmP__H2W6G {
    font-family: Lato,sans-serif;
    margin: 0;
    font-size: 13px;
    font-weight: 700;
}
/* сагсанд сонгогдсон бүтээгдэхүүний хэлбэржүүлэлт */
.style_hgBplF__1diTO {
    display: flex;
    background-color: #009E7F;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    border-radius: 200px;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    width: 50px;
    top: 0;
    right: 0;
}
.style_jJUnxI__2yNBC {
    border: none;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 7px;
    cursor: pointer;
    font-size: 8px;
}
.style_gSFvVa__A94we {
    font-family: Lato,sans-serif;
    pointer-events: none;
    font-size: 12px;
}
.style_colorred__LH1LZ{
    color: red;
}
.style_black__3KDaQ{
    color: #0D1136
}
/* бүтээгдэхүүн */
body {
    background: #F7F7F7;
}
.style_producttitle__2HJ2a {
  font-family: Lato,sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #0D1136;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.style_grPQmX__3-_zY {
    margin: 5px 5px;
    display: grid;
    grid-gap: 3px;
    grid-template-columns: repeat(1,minmax(180px,1fr));
}
@media screen and (min-width: 375px){
  .style_grPQmX__3-_zY {
    grid-template-columns: repeat(2,minmax(180px,1fr));
  }
}
@media screen and (min-width: 630px){
  .style_grPQmX__3-_zY {
    grid-template-columns: repeat(3,minmax(180px,1fr));
    margin: 7px 7px;
    grid-gap: 5px;
  }
}
@media screen and (min-width: 900px){
  .style_grPQmX__3-_zY {
    grid-template-columns: repeat(4,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1200px){
  .style_grPQmX__3-_zY {
    grid-template-columns: repeat(5,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1500px){
  .style_grPQmX__3-_zY {
    grid-template-columns: repeat(6,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1800px){
  .style_grPQmX__3-_zY {
    grid-template-columns: repeat(7,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
.style_ejsBAM__1kRVs {
    box-sizing: border-box;
    min-width: 0px;
    margin: 20px 0px 0px;
    text-align: center;
}
.style_iZvlvx__ipZia {
    --swiper-theme-color: #007aff;
    --swiper-navigation-size: 44px;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
       -moz-text-size-adjust: 100%;
            text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px 15px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    border-radius: 6px;
    -webkit-appearance: none;
            appearance: none;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    height: 38px;
    text-decoration: none;
    font-family: inherit;
    color: rgb(0, 158, 127);
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(241, 241, 241);
    font-size: 14px;
    display: inline-flex;
}
.style_iZvlvx__ipZia:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 158, 127);
    border-color: rgb(0, 158, 127);
}
.style_loadmore__20cFQ{
    margin: 0px 10px;
}
.style_loader__1cguN {
    color: #009e7f;
    font-size: 14px;
    font-weight:bolder;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20px auto;
    position: relative;
    transform: translateZ(0);
    animation: style_load6__MG61k 1.7s infinite ease, style_round__3-UMD 1.7s infinite ease;
}
.style_center__3BOCZ{
  padding-bottom: 50px;
}
.style_loader2__34EQL {
    color: #009e7f;
    font-size: 60px;
    font-weight:bolder;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    transform: translateZ(0);
    animation: style_load6__MG61k 1.7s infinite ease, style_round__3-UMD 1.7s infinite ease;
}
@keyframes style_load6__MG61k {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@keyframes style_round__3-UMD {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.search-box {
  border: none;
  border-bottom: 1px solid #009E7F;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: 18px;
  font-family: Lato,sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #009E7F;
}
@media screen and (min-width: 1200px){
  .search-box {
    line-height: 20px;
    font-size: 16px;
    color: red;
  }
}
.search-box:focus {
  outline: none;
}
.style_cardList__2T0bZ {
  margin: 5px 5px;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(1, minmax(180px, 1fr));
}
/* @media (max-width: 575px) {
  .cardList { 
    grid-template-columns: auto auto;
  }
} */
@media screen and (min-width: 375px) {
  .style_cardList__2T0bZ {
    grid-template-columns: repeat(2, minmax(180px, 1fr));
  }
}
@media screen and (min-width: 630px) {
  .style_cardList__2T0bZ {
    grid-template-columns: repeat(3, minmax(180px, 1fr));
    margin: 7px 7px;
    grid-gap: 5px;
  }
}
@media screen and (min-width: 900px) {
  .style_cardList__2T0bZ {
    grid-template-columns: repeat(4, minmax(180px, 1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
/* @media screen and (min-width: 1200px){
  .cardList {
    grid-template-columns: repeat(5,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1500px){
  .cardList {
    grid-template-columns: repeat(6,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
} */
.style_loader__3oifp {
  color: #009e7f;
  font-size: 14px;
  font-weight: bolder;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20px auto;
  position: relative;
  transform: translateZ(0);
  animation: style_load6__3I_Hr 1.7s infinite ease, style_round__3pUvB 1.7s infinite ease;
}

.style_deletebtn__2h20u {
  border:none;
    border-radius: 15px;
    font-size: 15px;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;
    -webkit-appearance: none;
            appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: inherit;
    background-color: #fff;
    color: #d9534f;
    width: 50px;
}
.style_cardContainer__3A5Ca {
  /* border: 1px solid gray; */
  position: relative;
  width: 90%;
  color: black; 
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
  transition: 0.3s;display: inline-block;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.25 ease-out;
  margin: 10px;
  height: 180px;
}
  
.style_cardContainer__3A5Ca img {
  object-fit: cover;
  object-position: center;
}
.style_cardContainer__3A5Ca:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

 .style_text__dtWJ7{
  color: rgb(13, 17, 54);

  text-align: center;
  /* text-transform: capitalize; */
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  overFlow: hidden;
  font-size: 18px;
  font-style: bold;
  font-weight: 700;
  font-family: Lato,sans-serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* white-space: nowrap; */
}
@media screen and (max-width: 375px){
  .style_text__dtWJ7 {
    font-size: 12px;
  }
}
.style_dHQHGmImg__g2NKO {
    width: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* 
.item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
} */

@media (max-width: 600px) {
    .style_dHQHGmImg__g2NKO {
        width: 100%;
        height:100%;;
        text-align: center;
        align-content: center;
        text-align: center;
        align-items: center;
    }
}
.style_kOmOCE__3LJzv {
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    border-radius: 6px;
    background-color: #F7F7F7;
    border: 1px solid #F7F7F7;
    margin-bottom: 15px;
    margin-right: 15px;
    position: relative;
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #0D1136;
    line-height: 24px;
    cursor: pointer;
    width: 100%;
    transition: all 0.25s ease;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    padding: 15px;
}
.style_kOmOCE__3LJzv.style_active__1u7np {
    border: 1px solid #009E7F;
    background-color: #ffffff;
}
.style_kOmOCE__3LJzv input[type='radio'] {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.style_kOmOCE__3LJzv:hover .style_jTaKCq__3SBmA {
    opacity: 1;
    visibility: visible;
}
.style_jNeWVx__3IXWC {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #0D1136;
    line-height: 1.2;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.style_hsUQDG__1IX-r {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #424561;
}
.style_jTaKCq__3SBmA {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
}
.style_icjzhS__4Xi50.style_editbtncheckout__qfTeV {
    background-color: #009E7F;
}
.style_icjzhS__4Xi50.style_deletebtncheckout__3-l4D {
    background-color: #ff5b60;
}
.style_icjzhS__4Xi50 {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 5px;
    cursor: pointer;
    outline: 0;
    padding: 0;
    color: #ffffff;
}
.style_icjzhS__4Xi50 i {
    display: block;
    font-size: 8px;
    height: auto;
}
.style_bCfOXr__1Y1bu {
    background-color: #ffffff;
    padding: 30px;
    padding-bottom: 20px;
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
}
@media (max-width: 767px){
    .style_bCfOXr__1Y1bu {
        padding: 20px;
    }
}
.style_iDDlIV__2cYKy {
    font-family: Lato,sans-serif;
    font-weight: 400;
    color: #0D1136;
    line-height: 1.2;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
}
@media (max-width: 767px){
    .style_iDDlIV__2cYKy {
        font-size: 19px;
    }
}
@media (max-width: 600px){
    .style_iDDlIV__2cYKy::before {
        width: 30px;
        height: 30px;
        font-size: 15px;
    }
}
.style_iDDlIV__2cYKy:before {
    counter-increment: section-counter;
    content: counter(section-counter);
    color: #ffffff;
    font-size: 19px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009E7F;
    margin-right: 15px;
}
@media (max-width: 600px){
    .style_iDDlIV__2cYKy:before {
        width: 30px;
        height: 30px;
        font-size: 15px;
    }
}
.style_dWZUxg__jY_o- {
    display: flex;
    flex-direction: column;
}
.style_leCXcG__1F9Rg {
    display: flex;
    flex-wrap: wrap;
}
/* .dWZUxg label {
    -webkit-flex: calc(33.333333333% - 10px);
    -ms-flex: calc(33.333333333% - 10px);
    flex: calc(33.333333333% - 10px);
    max-width: calc(33.333333333% - 10px);
} */
.style_jNBJTJ__1Paz9 {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0px;
    outline: 0px;
    border-radius: 0px;
    padding: 0px;
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: rgb(0, 158, 127);
    position: absolute;
    top: 40px;
    right: 30px;
}
@media (max-width: 767px){
    .style_jNBJTJ__1Paz9 {
        top: 27px;
        right: 20px;
    }
}
.style_hUFhpq__3D6Ba {
    box-sizing: border-box;
    min-width: 0;
    margin: 0;
    margin-right: 8px;
}
.style_eTKfHA__3UBVl {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(119, 121, 140);
    line-height: 1.5;
    margin-top: 30px;
    display: block;
}
.style_jZnGlQ__2naPy {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(255, 91, 96);
    margin-left: 5px;
    cursor: pointer;
    line-height: 1.5;
}
.style_ExTwM__1GBuH {
    margin-top: 25px;
}
.style_ddKXqe__Dek2K {
    padding: 0px 30px;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 158, 127);
    transition: all 0.3s ease 0s;
    border-radius: 6px;
    -webkit-appearance: none;
            appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    text-decoration: none;
    font-family: inherit;
    border: 0px;
    height: 48px;
    width: 100%;
}
.style_gwBSuY__1RMJq {
    width: 270px;
    flex-shrink: 0;
    padding-top: 20px;
}
@media (max-width: 1200px) and (min-width: 768px){
    .style_gwBSuY__1RMJq {
        width: 260px;
    }
}
@media (max-width: 767px){
    .style_gwBSuY__1RMJq {
        order: 0;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 40px;
        padding-bottom: 30px;
        position: relative !important;
    }
}
.style_kSbJGA__Vyjgx {
    width: 100%;
    display: flex;
    flex-direction: column;
}
@media (max-width: 990px) and (min-width: 768px){
    .style_kSbJGA__Vyjgx {
        padding-right: 15px;
    }
}
.style_dAPHLO__3qtQi {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    text-align: center;
    margin-bottom: 40px;
}

.style_checkoutscrollbar__3v-sw {
    height: 100%;
    max-height: calc(100vh - 245px);
    max-height: 390px;
    padding-right: 15px;
    position: relative;
    overflow: auto !important;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}
@media (max-width: 767px){
    .style_checkoutscrollbar__3v-sw{
        height: auto;
    }
}
.style_ospadding__1eXkj {
    box-sizing: inherit;
    direction: inherit;
    overflow: auto;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
}
.style_hXnywk__2GAnM {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
}
.style_fBhgXs__UGzw9 {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 25px;
}
.style_iPCvVb__2UqiZ {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 700;
    color: #0D1136;
}
.style_kZXmaV__39gfV {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
    margin: 0 12px;
}
.style_djStHc__1qXH9 {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
    margin-right: 15px;
}
.style_jDKalT__2ar24 {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
    margin-left: auto;
}

.style_vNVrf__20fSE {
    border-top: 1px solid #E6E6E6;
    padding: 20px 15px 0;
    margin-top: 20px;
}
.style_iZImhr__3uSmC {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}
.style_kTPYoz__3K8PX {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
}
.style_kxbXCM__2t25l {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 700;
    color: #0D1136;
}
.style_bsOIsI__3fuvd {
    margin-top: 25px;
}
.style_flexrow__1EhQK{
    display: flex;
    flex-direction: column;
}
.style_innerRndComponent__3-g8m {
    width: 100%;
    padding: 30px;
    height: auto;
    background-color: rgb(247, 248, 249);
    border: 0px;
    box-sizing: border-box;
    border-radius: 2px;
}
.style_bHlADF__2J6cX {
    font-family: sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 15px;
}
.style_deMkW___hCjA {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.style_iapefQ__1qbKz {
    display: block;
    width: 100%;
    padding: 0 18px;
    -webkit-appearance: none;
    appearance: none;
    font-family: Lato,sans-serif;
    font-size: 15px;
    line-height: inherit;
    border: 1px solid;
    border-color: #f1f1f1;
    border-radius: 6px;
    background-color: #ffffff;
    color: #0D1136;
    height: 48px;
    transition: all 0.25s ease;
}
.style_iapefQ__1qbKz:hover, .style_iapefQ__1qbKz:focus {
    outline: 0;
}
.style_iapefQ__1qbKz:focus {
    border-color: #009E7F;
}
.style_bUSVGR__2g28I textarea {
    height: auto;
    min-height: 171px;
    padding-top: 15px;
    resize: none;
}
.style_dwDYsr__2WU26 {
    width: 100%;
    height: 44px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    color: #ffffff;
    background-color: #009E7F;
    /* -webkit-transition: all 0.3s ease; */
    transition: all 0.3s ease;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    -webkit-appearance: none;
            appearance: none;
    /* display: -webkit-box; */
    /* display: -webkit-flex; */
    display: flex;
    /* -webkit-align-items: center; */
    align-items: center;
    /* -webkit-justify-content: center; */
    justify-content: center;
    /* -webkit-flex-shrink: 0; */
    flex-shrink: 0;
    text-align: center;
    /* height: 38px; */
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: inherit;
    border: 0;
}
.style_reuseModalCloseBtn__3dPOX {
    right: 10px!important;
    background-color: #ffffff!important;
    color: #222222!important;
    border-radius: 15px!important;
    padding: 0 9px!important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.4);
}

.style_reuseModalCloseBtn__3dPOX {
    position: fixed;
    top: 10px;
    right: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #ffffff;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    z-index: 9999999;
}
.style_reuseModalCloseBtn__3dPOX i{
    font-weight: 600;
}
.style_innerRndComponent__2bBl- {
    width: 100%;
    padding: 30px;
    height: auto;
    background-color: rgb(247, 248, 249);
    border: 0px;
    box-sizing: border-box;
    border-radius: 2px;
}
.style_bHlADF__5Y27j {
    font-family: sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 15px;
}
.style_deMkW__3JymN {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.style_iapefQ__3PedM {
    display: block;
    width: 100%;
    padding: 0 18px;
    -webkit-appearance: none;
    appearance: none;
    font-family: Lato,sans-serif;
    font-size: 15px;
    line-height: inherit;
    border: 1px solid;
    border-color: #f1f1f1;
    border-radius: 6px;
    background-color: #ffffff;
    color: #0D1136;
    height: 48px;
    transition: all 0.25s ease;
}
.style_iapefQ__3PedM:hover, .style_iapefQ__3PedM:focus {
    outline: 0;
}
.style_iapefQ__3PedM:focus {
    border-color: #009E7F;
}
.style_bUSVGR__IEDV2 textarea {
    height: auto;
    min-height: 171px;
    padding-top: 15px;
    resize: none;
}
.style_dwDYsr__3oD92 {
    width: 100%;
    height: 44px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    color: #ffffff;
    background-color: #009E7F;
    /* -webkit-transition: all 0.3s ease; */
    transition: all 0.3s ease;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    -webkit-appearance: none;
            appearance: none;
    /* display: -webkit-box; */
    /* display: -webkit-flex; */
    display: flex;
    /* -webkit-align-items: center; */
    align-items: center;
    /* -webkit-justify-content: center; */
    justify-content: center;
    /* -webkit-flex-shrink: 0; */
    flex-shrink: 0;
    text-align: center;
    /* height: 38px; */
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: inherit;
    border: 0;
}
.style_reuseModalCloseBtn__1359W {
    right: 10px!important;
    background-color: #ffffff!important;
    color: #222222!important;
    border-radius: 15px!important;
    padding: 0 9px!important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.4);
}

.style_reuseModalCloseBtn__1359W {
    position: fixed;
    top: 10px;
    right: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #ffffff;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    z-index: 9999999;
}
.style_reuseModalCloseBtn__1359W i{
    font-weight: 600;
}
.style_kqrKyZ__1wKuJ {
    background-color: #F7F7F7;
    height: 100%;
}
.style_dcTgel__1ZMhS {
    width: 100%;
    display: flex;
    position: relative;
}
@media (max-width: 990px){
    .style_kqrKyZ__1wKuJ {
        background-color: #ffffff;
    }
}
@media (max-width: 1100px){
    .style_dcTgel__1ZMhS {
        padding-left: 0;
        padding-right: 0;
    }
}
.style_hGqnHy__2Hzav {
    width: 100%;
    display: flex;
    counter-reset: section-counter;
}
@media (max-width: 767px){
    .style_hGqnHy__2Hzav {
        flex-direction: column;
    }
}
@media (min-width: 990px){
    .style_hGqnHy__2Hzav {
        width: 970px;
        margin-left: auto;
        margin-right: auto;
    }
}
.style_hwlTmd__1VlJT {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    padding: 20px;
}
@media (max-width: 990px){
    .style_hwlTmd__1VlJT {
        margin-right: 10px;
    }
}
@media (max-width: 767px){
    .style_hwlTmd__1VlJT {
        order: 1;
        margin-right: 0;
    }
}
.style_loader__1Y6AG {
    color: #1ad15a;
    font-size: 40px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    transform: translateZ(0);
    animation: style_load6__28fFz 1.7s infinite ease, style_round__3Fnn6 1.7s infinite ease;
  }
  @keyframes style_load6__28fFz {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_round__3Fnn6 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .style_savebtn__3v85y {
    height: 48px;
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 193, 7);
    padding: 0px;
    border-radius: 48px;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    border: 0px;
    outline: 0px;
    cursor: pointer;
    margin-bottom: 15px;
    margin-left: 15px;
    display: flex;
    font-weight: bold;
    color: white;
  }
/* webview item [start] */
.style_lihBKG__2QIX2 {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
}
.style_hjcFuj__ZLFx5 {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
}
.style_hjcFuj__ZLFx5 span {
    font-weight: 400;
}
.style_iTdekY__3CMGz {
    font-family: Lato,sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #2e70fa;
    line-height: 1;
    background-color: rgba(46,112,250,0.1);
    padding: 10px 5px;
    border-radius: 6px;
}
.style_guZSJO__1YgZK {
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.style_Bmugg__XxCoj {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #0D1136;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}
.style_Bmugg__XxCoj.style_price__cnEO8 {
    font-weight: 700;
} 
.style_Bmugg__XxCoj:last-child {
    margin-bottom: 0;
}
.style_font11__WUcLb{
    font-size: 11px;
}
/* webview item end */

/* webview detai start */
.style_cJGveh__3G8DJ {
    width: 100%;
    min-height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    border: 1px solid #f1f1f1;
}
@media only screen and (max-width: 1199px){
    .style_cJGveh__3G8DJ {
        height: 657px;
    }
}
.style_jjWgRb__2Xac1 {
    width: 100%;
    display: flex;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
}
.style_bLiRNB__18apv {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #f1f1f1;
    padding: 20px;
}
.style_bLiRNB__18apv h3 {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 10px;
}
.style_fbmTwo__YiK-y {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #77798C;
    line-height: 1.5;
}
.style_bPJmao__1wuVE {
    width: 235px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 20px;
}
.style_cqjOZT__1EGQo {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #77798C;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.style_cFTfrZ__2gJbW {
    color: #0D1136;
}
.style_cqjOZT__1EGQo.style_grandTotal__1ayFz {
    font-weight: 700;
    color: #0D1136;
}
.style_cqjOZT__1EGQo:last-child {
    margin-bottom: 0;
}
.style_lbTChn__5dvVq {
    width: 100%;
    display: flex;
    padding: 30px 25px;
    border-bottom: 1px solid #f1f1f1;
}
.style_foKLRz__3qoeY {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
}
.style_foKLRz__3qoeY:first-child .style_progressboxstyle__34CYr {
    width: 50%;
    left: 50%;
}
.style_foKLRz__3qoeY:last-child .style_progressboxstyle__34CYr{
    width: 50%;
}
.style_jYdTsb__7oIfn {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 15px; */
}
/* progress bar */
.style_flwWPp__MVY6H {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #009E7F;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ffffff;
    z-index: 1;
    border: 1px dashed #009E7F;
}
.style_fRVGLf__2ZthF {
    width: 13px;
    height: 17px;
}
.style_fRVGLf__2ZthF i {
    width: 100%;
}
.style_wHIWv__szJ59 {
    width: 100%;
    height: 4px;
    background-color: #E6E6E6;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -2px;
}
.style_kNirdD__XY5hv {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.style_fcCkVG__2TVzY {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    text-align: center;
    padding: 0 8px;
}
.style_checked__1nITu {
    background-color: #009E7F;
    color: #ffffff;
}
.style_checked2__1k4mo {
    background-color: #9c2917;
    color: #000;
}
.style_rctable__3mwb2 {
    font-size: 12px;
    color: #666;
    line-height: 1.5;
    box-sizing: border-box;
    position: relative;
    overflow: auto;
    height: 350px;
}
.style_cAgIfa__33Jfs {
    border-collapse: collapse;
}
.style_rctable__3mwb2 table {
    border-spacing: 0px;
    width: 100%;
}
.style_rctable__3mwb2 th, .style_rctable__3mwb2 td {
    padding: 0;
    position: relative;
    border: 1px solid red;
    border-top: 0;
    border-left: 0;
    transition: box-shadow 0.3s;
    padding: 16px 8px;
    box-sizing: border-box;
    white-space: normal;
    word-break: break-word;
}
.style_cAgIfa__33Jfs thead th:first-child {
    padding-left: 110px;
    text-align: left;
}
.style_cAgIfa__33Jfs thead th:last-child {
    text-align: right;
}
.style_cAgIfa__33Jfs thead th {
    padding: 8px 20px;
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #0D1136;
    border: none;
}
.style_rctable__3mwb2 thead td, .style_rctable__3mwb2 thead th {
    background: #f7f7f7;
    text-align: center;
}
.style_cAgIfa__33Jfs tr td {
    /* padding: 20px; */
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #0D1136;
    border-bottom: 0;
    border: none;
}
.style_lhXkXj__PitHZ {
    display: flex;
    align-items: center;
}
.style_kONoVC__1wT4R {
    width: 75px;
    height: 75px;
    display: flex;
    flex-shrink: 0;
}
.style_kONoVC__1wT4R img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.style_iVTGVp__17mYx {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    overflow: hidden;
}
.style_kIgOkd__2U0Jb {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}
.style_bnnPwi__O4dkO {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #77798C;
    margin-bottom: 5px;
}
.style_kcpNyZ__m839i {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #009E7F;
}
.style_tdright__1ZQX6{
    text-align: right;
}
.style_tdright__1ZQX6 p{
    margin: 0px;
}
.style_notorder__100UO{
    padding: 20px;
    color: red;
}
/* webview detail end */

/* mobile view start */
.style_fDCATW__1ZX4B {
    display: none;
}
@media only screen and (max-width: 989px){
    .style_fDCATW__1ZX4B {
        width: 100%;
        display: flex;
    }
}
.style_bGLdjy__3JvtJ {
    width: 100%;
    padding: 0 20px 20px;
}
@media (max-width: 767px){
    .style_bGLdjy__3JvtJ {
        padding: 0;
    }
}
.style_rccollapse__1wV1_ {
    background-color: transparent;
    border-radius: 0;
    border: 0;
}
.style_rccollapse__1wV1_ > .style_rccollapseitem__NV0SO {
    margin-bottom: 15px;
    background-color: #F7F7F7;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex-shrink: 0;
    border: 1px solid transparent;
}
.style_bGLdjy__3JvtJ .style_rccollapse__1wV1_ > .style_rccollapseitem__NV0SO.style_rccollapseitemactive__3vnSX {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
}
.style_rccollapsecontentinactive__jx92R {
    display: none;
}
.style_rccollapse__1wV1_ > .style_rccollapseitem__NV0SO > .style_rccollapseheader__2eQ4T {
    padding: 0;
    outline: 0;
    display: flex;
    align-items: center;
    line-height: 22px;
    color: #666;
    cursor: pointer;
}
.style_rccollapse__1wV1_ > .style_rccollapseitem__NV0SO > .style_rccollapseheader__2eQ4T .style_arrow__3MfRl {
    display: inline-block;
    content: '\20';
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 4px solid #666;
    vertical-align: middle;
    margin-right: 8px;
}
.style_hzdtju__18__Y {
    width: 100%;
}
.style_rccollapse__1wV1_ > .style_rccollapseitem__NV0SO .style_rccollapsecontent__2b_JE {
    padding: 0px;
}

.style_rccollapsecontent__2b_JE {
    overflow: hidden;
    color: #666;
    padding: 0 16px;
    background-color: #fff;
}
.style_rccollapse__1wV1_ > .style_rccollapseitem__NV0SO .style_rccollapsecontent__2b_JE > .style_rccollapsecontentbox__1kEU1 {
    box-sizing: border-box;
    padding: 0px;
    margin: 0;
}
.style_bmogfF__1lrYD {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.style_eUHqXZ__55Lfq {
    flex-direction: column;
}
.style_cwZtQI__1GN_1 {
    border-bottom: 1px solid #f1f1f1;
    border-right: 0;
}
.style_fgcIfk__28SLF {
    width: 100%;
}
@media (max-width: 767px){
    .style_hALMhu__2DvaU {
        flex-direction: column;
    }
    .style_foKLRz__3qoeY {
        flex-direction: row;
        margin-bottom: 30px;
    }
    .style_jYdTsb__7oIfn {
        width: auto;
        margin-bottom: 0;
    }
    .style_foKLRz__3qoeY:first-child .style_progressboxstyle__34CYr {
        width: 3px;
        height: 100%;
        top: 50%;
    }
    .style_foKLRz__3qoeY:last-child .style_progressboxstyle__34CYr{
        width: 3px;
        height: 100%;
        top: -50%;
    }
    .style_kNirdD__XY5hv {
        margin-top: 0px;
        width: 100%;
        align-items: flex-start;
        margin-left: 20px;
    }
    .style_fcCkVG__2TVzY {
        padding: 0;
    }
    .style_wHIWv__szJ59 {
        width: 3px;
        height: 200%;
        left: 50%;
        top: -50%;
        margin-top: 0;
        margin-left: -1px;
    }
}
@media (max-width: 600px){
    .style_rctable__3mwb2 table{
        width: 560px;
    }
}
/* mobile view end */
/* web view */
.style_eXUcye__3xRcK {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
    padding: 10px 70px 40px;
}
@media only screen and (min-width: 991px) and (max-width: 1280px){
    .style_eXUcye__3xRcK {
        padding: 10px 15px 60px;
    }
}
@media only screen and (max-width: 990px){
    .style_eXUcye__3xRcK {
        padding: 10px 0 60px;
    }
}
.style_TVBQD__15WE3 {
    width: calc(100% - 330px);
    display: flex;
    align-items: flex-start;
    margin: auto;
}
@media only screen and (max-width: 1199px){
    .style_TVBQD__15WE3 {
        width: 100%;
        padding: 0 20px;
    }
}
.style_duuioQ__38KCP {
    display: none;
}
@media only screen and (min-width: 990px){
    .style_duuioQ__38KCP {
        display: flex;
    }
}
.style_fgelJT__1CHu_ {
    width: 330px;
    height: 657px;
    display: flex;
    flex-direction: column;
    border: 1px solid #f1f1f1;
    flex-shrink: 0;
    margin-right: 30px;
}
@media only screen and (max-width: 1199px){
    .style_fgelJT__1CHu_ {
        height: 657px;
        width: 310px;
        margin-right: 20px;
    }
}
.style_kVstku__38jjk {
    font-family: Poppins,sans-serif;
    font-size: 21px;
    font-weight: 600;
    color: #0D1136;
    margin: 25px 0;
    padding: 0px 20px;
}
.style_order-scrollbar__v8gks {
    height: 100%;
    min-height: 420px;
}
.style_bGLdjy__3Yf6F {
    width: 100%;
    padding: 0 20px 20px;
    overflow: auto;
}
.style_dEBCwJ__2VV4H {
    background-color: #F7F7F7;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex-shrink: 0;
    border: 2px solid transparent;
}
.style_dEBCwJ__2VV4H.style_active__D1_GB {
    border: 2px solid #009E7F;
}
.style_dEBCwJ__2VV4H input[type='radio'] {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.style_eTHeCj__3AKPb {
    background-color: #F7F7F7;
    position: relative;
    padding: 10px 0 60px 0;
    min-height: 100vh;
}
.style_fZqxml__3isA9 {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    padding: 60px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 767px){
    .style_fZqxml__3isA9 {
        padding: 50px 25px;
    }
}
@media (max-width: 990px){
    .style_fZqxml__3isA9 {
        padding: 50px 45px;
    }
}
@media (min-width: 991px){
    .style_fZqxml__3isA9 {
        width: 870px;
    }
}
.style_homebtn__-rvU4 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #77798C;
    padding: 5px 15px;
    height: 36px;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
    position: absolute;
    top: 15px;
    right: 15px;
    transition: 0.15s ease-in-out;
}
@media (max-width: 767px){
    .style_home-btn__2ORPL {
        font-size: 13px;
        height: 34px;
        padding: 5px 12px;
    }
}
.style_eIRhpR__xuuQf {
    margin-bottom: 60px;
}
@media (max-width: 767px){
    .style_eIRhpR__xuuQf {
        margin-bottom: 30px;
    }
}
.style_QVyIh__xQUa6 {
    font-family: Poppins,sans-serif;
    font-size: 21px;
    font-weight: 600;
    color: #0D1136;
    line-height: 1;
    margin-bottom: 32px;
}
@media (max-width: 767px){
    .style_QVyIh__xQUa6 {
        font-size: calc(15px + 1px);
        margin-bottom: 25px;
    }
}
.style_bvSdvR__3kgML {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.style_eIHWxM__3jZ1Z {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
@media (max-width: 767px){
    .style_eIHWxM__3jZ1Z {
        flex-wrap: wrap;
    }
}
.style_gSskSI__YstYb:first-child {
    padding-left: 0;
    border-left: 0;
}
.style_gSskSI__YstYb {
    flex-grow: 1;
    padding: 0 15px;
    border-left: 1px solid #f1f1f1;
}
@media (max-width: 767px){
    .style_gSskSI__YstYb {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 20px;
        padding: 0;
        border: 0;
    }
}
.style_gSskSI__YstYb .style_title__3aj6e {
    margin-bottom: 10px;
}
.style_bHcUtG__1AQOe {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 700;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.style_bvSdvR__3kgML:last-child {
    color: #424561;
}
.style_bvSdvR__3kgML {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.style_dEojFW__37LeK {
    margin-bottom: 60px;
}
@media (max-width: 767px){
    .style_dEojFW__37LeK {
        margin-bottom: 50px;
    }
}
.style_hXfWwV__3Yxqx {
    display: flex;
    margin-bottom: 20px;
}
.style_jHaRgo__e-L4l {
    flex-basis: 210px;
    max-width: 210px;
    flex-shrink: 0;
    position: relative;
}
@media (max-width: 767px){
    .style_jHaRgo__e-L4l {
        flex-basis: 105px;
        max-width: 105px;
    }
}
.style_jHaRgo__e-L4l:after {
    content: ':';
    position: absolute;
    top: -1px;
    right: -2px;
    line-height: 1;
}
.style_gADUYd__3HHk8 {
    padding-left: 90px;
}
@media (max-width: 767px){
    .style_gADUYd__3HHk8 {
        padding-left: 20px;
    }
}
.style_bvSdvR__3kgML:last-child {
    color: #424561;
}
.style_bvSdvR__3kgML {
    font-family: Lato,sans-serif;
    font-size: 15px;
    color: #0D1136;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    margin: 0;
}
.style_footerwarn__llbVH{
    margin-bottom: 10px;
    padding: 10px 2px;
    border: 1px solid  #009e7f;
    border-radius: 5px;
}

.style_rub__2tiRi {
  display: flex;
  flex-direction: column;
  color: #009e7f;
}
.style_rub2__195XT {
  display: flex;
  flex-direction: row;
}
.style_dateP__1XurT {
  font-size: 10px;
  margin-bottom: 0px;
}
.style_dada__mFVSR {
  display: flex;
  flex-direction: column;
}
.style_haha__3xp_g {
  font-size: small;
  margin-left: 5px;
  margin-bottom: 5px;
}

.style_loader__v_YXA {
  color: #009e7f;
  font-size: 14px;
  font-weight:bolder;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20px auto;
  position: relative;
  transform: translateZ(0);
  animation: style_load6__368Sz 1.7s infinite ease, style_round__3hiCb 1.7s infinite ease;
}
.style_center__LlJn9{
padding-bottom: 50px;
}
.style_loader2__2Jnsa {
  color: #009e7f;
  font-size: 60px;
  font-weight:bolder;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20% auto;
  position: relative;
  transform: translateZ(0);
  animation: style_load6__368Sz 1.7s infinite ease, style_round__3hiCb 1.7s infinite ease;
}
@keyframes style_load6__368Sz {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes style_round__3hiCb {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.style_mainDiv__1p4x6 {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    width: 90%;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1.1rem;
    max-width: 400px;
    margin: 100px auto;
  }
  .style_errorMsg__2cibp {
    margin-left: 10px;
    color: red;
  }
  .style_loginBtn__14jzp {
    background-color: rgb(0, 158, 127);
    color: white;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
  .style_alertMessage__3rquP
  {
    color: #28a745;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
.style_loader__3Vhkq {
    color: #1ad15a;
    font-size: 60px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    transform: translateZ(0);
    animation: style_load6__3XfdZ 1.7s infinite ease, style_round__1Bt4G 1.7s infinite ease;
  }
  @keyframes style_load6__3XfdZ {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_round__1Bt4G {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.style_mainDiv__rIwv5 {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  width: 90%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.1rem;
  max-width: 400px;
  margin: 100px auto;
}
.style_errorMsg__3gCId {
  margin-left: 10px;
  color: red;
}
.style_loginBtn__2qxDY {
  background-color: rgb(0, 158, 127);
  color: white;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.style_alertMessage__H-9wi
{
  color: #28a745;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
main {
  height: 100vh;
  width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Merriweather Sans", sans-serif;
}
main #errorText {
  font-size: 22px;
  margin: 14px 0;
}
main #errorLink {
  font-size: 20px;
  padding: 12px;
  border: 1px solid;
  color: #000;
  background-color: transparent;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}
main #errorLink:hover, main #errorLink:active {
  color: #fff;
  background: #000;
}
main #g6219 {
  transform-origin: 85px 4px;
  animation: an1 12s 0.5s infinite ease-out;
}

@keyframes an1 {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(3deg);
  }
  15% {
    transform: rotate(-2.5deg);
  }
  25% {
    transform: rotate(2deg);
  }
  35% {
    transform: rotate(-1.5deg);
  }
  45% {
    transform: rotate(1deg);
  }
  55% {
    transform: rotate(-1.5deg);
  }
  65% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  85% {
    transform: rotate(2.5deg);
  }
  95% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0);
  }
}
.style_kqrKyZ__pygMJ {
  background-color: #f7f7f7;
  height: 100vh;
}
.style_dcTgel__2TUwy {
  width: 100%;
  display: flex;
  /* padding: 30px 60px 60px; */
  position: relative;
}
@media (max-width: 990px) {
  .style_dcTgel__2TUwy {
    /* padding-top: 60px; */
  }
  .style_kqrKyZ__pygMJ {
    background-color: #ffffff;
  }
}
@media (max-width: 1100px) {
  .style_dcTgel__2TUwy {
    padding-left: 0;
    padding-right: 0;
  }
}
.style_hGqnHy__2vXau {
  width: 100%;
  display: flex;
  counter-reset: section-counter;
}
@media (max-width: 767px) {
  .style_hGqnHy__2vXau {
    flex-direction: column;
  }
}
@media (min-width: 990px) {
  .style_hGqnHy__2vXau {
    width: 970px;
    margin-left: auto;
    margin-right: auto;
  }
}
.style_hwlTmd__19fIB {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  padding: 20px;
}
@media (max-width: 990px) {
  .style_hwlTmd__19fIB {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .style_hwlTmd__19fIB {
    order: 1;
    margin-right: 0;
  }
}

.style_gwBSuY__1TO5I {
  width: 350px;
  flex-shrink: 0;
  padding-top: 20px;
}
@media (max-width: 1200px) and (min-width: 768px) {
  .style_gwBSuY__1TO5I {
    width: 260px;
  }
}
@media (max-width: 767px) {
  .style_gwBSuY__1TO5I {
    order: 0;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    position: relative !important;
  }
}
.style_kSbJGA__1Jpyy {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 990px) and (min-width: 768px) {
  .style_kSbJGA__1Jpyy {
    padding-right: 15px;
  }
}
.style_dAPHLO__6r5pH {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #0d1136;
  text-align: center;
  margin-bottom: 40px;
}
.style_colDIV__2lwei {
  display: flex;
  flex-direction: row;
}
.style_colDIV2__3emXS {
  display: flex;
  flex-direction: row;
}
.style_phoneLbl__28Ep_ {
  font-size: 15px;
}
.style_subBtn__3AHRy {
  background-color: #009e7f;
  color: white;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin-top: 20px;
}

.style_loader__1ibq2 {
    color: #1ad15a;
    font-size: 60px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    transform: translateZ(0);
    animation: style_load6__35qW- 1.7s infinite ease, style_round__2dk8F 1.7s infinite ease;
  }
  @keyframes style_load6__35qW- {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
        -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
        -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
        -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
        -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
        -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
        -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
        0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes style_round__2dk8F {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.style_mainDivReg__2leOI {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  width: 90%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.1rem;
  max-width: 500px;
  margin: 50px auto;
}
.style_errorMsg__ifHBL {
  margin-left: 10px;
  color: red;
}
.style_registerBtn__366Ij {
  background-color: #009e7f;
  color: white;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.style_formInput__3yOYn {
  width: 100%;
}
.style_formInput__3yOYn label {
  position: relative;
  display: block;
  width: 100%;
  min-height: 45px;
}
.style_formInput__3yOYn .style_placeholder__2XmpJ {
  position: absolute;
  display: block;
  top: 23px;
  z-index: 2;
  font-size: 16px;
  transition: all 200ms ease-in-out;
  width: 100%;
  cursor: text;
  color: #c3c3c3;
}
.style_formInput__3yOYn input,
.style_formInput__3yOYn textarea {
  position: absolute;
  top: 15px;
  z-index: 1;
  width: 100%;
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid grey;
  transition: border-color 200ms ease-in-out;
  outline: none;
  padding: 0;
  margin: 0;
}
.style_formInput__3yOYn textarea {
  min-height: 30px;
  padding: 8px 0;
}
.style_formInput__3yOYn input {
  height: 30px;
}
.style_formInput__3yOYn input:focus,
.style_formInput__3yOYn input:valid,
.style_formInput__3yOYn textarea:focus,
.style_formInput__3yOYn textarea:valid {
  border-bottom: 2px solid #009e7f;
}
.style_formInput__3yOYn input:focus + .style_placeholder__2XmpJ,
.style_formInput__3yOYn input:valid + .style_placeholder__2XmpJ,
.style_formInput__3yOYn textarea:focus + .style_placeholder__2XmpJ,
.style_formInput__3yOYn textarea:valid + .style_placeholder__2XmpJ {
  top: 0;
  cursor: inherit;
  font-size: 14px;
  color: #009e7f;
}

