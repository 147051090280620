.kqrKyZ {
  background-color: #f7f7f7;
  height: 100vh;
}
.dcTgel {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* padding: 30px 60px 60px; */
  position: relative;
}
@media (max-width: 990px) {
  .dcTgel {
    /* padding-top: 60px; */
  }
  .kqrKyZ {
    background-color: #ffffff;
  }
}
@media (max-width: 1100px) {
  .dcTgel {
    padding-left: 0;
    padding-right: 0;
  }
}
.hGqnHy {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  counter-reset: section-counter;
}
@media (max-width: 767px) {
  .hGqnHy {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (min-width: 990px) {
  .hGqnHy {
    width: 970px;
    margin-left: auto;
    margin-right: auto;
  }
}
.hwlTmd {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 20px;
  padding: 20px;
}
@media (max-width: 990px) {
  .hwlTmd {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .hwlTmd {
    order: 1;
    margin-right: 0;
  }
}
