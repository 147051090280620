.kOmOCE {
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    border-radius: 6px;
    background-color: #F7F7F7;
    border: 1px solid #F7F7F7;
    margin-bottom: 15px;
    margin-right: 15px;
    position: relative;
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #0D1136;
    line-height: 24px;
    cursor: pointer;
    width: 100%;
    transition: all 0.25s ease;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    padding: 15px;
}
.kOmOCE.active {
    border: 1px solid #009E7F;
    background-color: #ffffff;
}
.kOmOCE input[type='radio'] {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.kOmOCE:hover .jTaKCq {
    opacity: 1;
    visibility: visible;
}
.jNeWVx {
    font-family: Lato,sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #0D1136;
    line-height: 1.2;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.hsUQDG {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #424561;
}
.jTaKCq {
    position: absolute;
    top: 10px;
    right: 10px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
}
.icjzhS.editbtncheckout {
    background-color: #009E7F;
}
.icjzhS.deletebtncheckout {
    background-color: #ff5b60;
}
.icjzhS {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 5px;
    cursor: pointer;
    outline: 0;
    padding: 0;
    color: #ffffff;
}
.icjzhS i {
    display: block;
    font-size: 8px;
    height: auto;
}