/* бүтээгдэхүүн */
body {
    background: #F7F7F7;
}
.producttitle {
  font-family: Lato,sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #0D1136;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.grPQmX {
    margin: 5px 5px;
    display: grid;
    grid-gap: 3px;
    grid-template-columns: repeat(1,minmax(180px,1fr));
}
@media screen and (min-width: 375px){
  .grPQmX {
    grid-template-columns: repeat(2,minmax(180px,1fr));
  }
}
@media screen and (min-width: 630px){
  .grPQmX {
    grid-template-columns: repeat(3,minmax(180px,1fr));
    margin: 7px 7px;
    grid-gap: 5px;
  }
}
@media screen and (min-width: 900px){
  .grPQmX {
    grid-template-columns: repeat(4,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1200px){
  .grPQmX {
    grid-template-columns: repeat(5,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1500px){
  .grPQmX {
    grid-template-columns: repeat(6,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1800px){
  .grPQmX {
    grid-template-columns: repeat(7,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
.ejsBAM {
    box-sizing: border-box;
    min-width: 0px;
    margin: 20px 0px 0px;
    text-align: center;
}
.iZvlvx {
    --swiper-theme-color: #007aff;
    --swiper-navigation-size: 44px;
    line-height: 1.5;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px 15px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    border-radius: 6px;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    height: 38px;
    text-decoration: none;
    font-family: inherit;
    color: rgb(0, 158, 127);
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(241, 241, 241);
    font-size: 14px;
    display: inline-flex;
}
.iZvlvx:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 158, 127);
    border-color: rgb(0, 158, 127);
}
.loadmore{
    margin: 0px 10px;
}
.loader {
    color: #009e7f;
    font-size: 14px;
    font-weight:bolder;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
.center{
  padding-bottom: 50px;
}
.loader2 {
    color: #009e7f;
    font-size: 60px;
    font-weight:bolder;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 20% auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@-webkit-keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
