.bCfOXr {
    background-color: #ffffff;
    padding: 30px;
    padding-bottom: 20px;
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.08);
}
@media (max-width: 767px){
    .bCfOXr {
        padding: 20px;
    }
}
.iDDlIV {
    font-family: Lato,sans-serif;
    font-weight: 400;
    color: #0D1136;
    line-height: 1.2;
    margin-bottom: 35px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 767px){
    .iDDlIV {
        font-size: 19px;
    }
}
@media (max-width: 600px){
    .iDDlIV::before {
        width: 30px;
        height: 30px;
        font-size: 15px;
    }
}
.iDDlIV:before {
    counter-increment: section-counter;
    content: counter(section-counter);
    color: #ffffff;
    font-size: 19px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #009E7F;
    margin-right: 15px;
}
@media (max-width: 600px){
    .iDDlIV:before {
        width: 30px;
        height: 30px;
        font-size: 15px;
    }
}
.dWZUxg {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.leCXcG {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
/* .dWZUxg label {
    -webkit-flex: calc(33.333333333% - 10px);
    -ms-flex: calc(33.333333333% - 10px);
    flex: calc(33.333333333% - 10px);
    max-width: calc(33.333333333% - 10px);
} */
.jNBJTJ {
    width: auto;
    height: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: transparent;
    border: 0px;
    outline: 0px;
    border-radius: 0px;
    padding: 0px;
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: rgb(0, 158, 127);
    position: absolute;
    top: 40px;
    right: 30px;
}
@media (max-width: 767px){
    .jNBJTJ {
        top: 27px;
        right: 20px;
    }
}
.hUFhpq {
    box-sizing: border-box;
    min-width: 0;
    margin: 0;
    margin-right: 8px;
}
.eTKfHA {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(119, 121, 140);
    line-height: 1.5;
    margin-top: 30px;
    display: block;
}
.jZnGlQ {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(255, 91, 96);
    margin-left: 5px;
    cursor: pointer;
    line-height: 1.5;
}
.ExTwM {
    margin-top: 25px;
}
.ddKXqe {
    padding: 0px 30px;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 158, 127);
    transition: all 0.3s ease 0s;
    border-radius: 6px;
    appearance: none;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    text-decoration: none;
    font-family: inherit;
    border: 0px;
    height: 48px;
    width: 100%;
}