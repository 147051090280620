.gwBSuY {
    width: 270px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-top: 20px;
}
@media (max-width: 1200px) and (min-width: 768px){
    .gwBSuY {
        width: 260px;
    }
}
@media (max-width: 767px){
    .gwBSuY {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 40px;
        padding-bottom: 30px;
        position: relative !important;
    }
}
.kSbJGA {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
@media (max-width: 990px) and (min-width: 768px){
    .kSbJGA {
        padding-right: 15px;
    }
}
.dAPHLO {
    font-family: Lato,sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #0D1136;
    text-align: center;
    margin-bottom: 40px;
}

.checkoutscrollbar {
    height: 100%;
    max-height: calc(100vh - 245px);
    max-height: 390px;
    padding-right: 15px;
    position: relative;
    overflow: auto !important;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
}
@media (max-width: 767px){
    .checkoutscrollbar{
        height: auto;
    }
}
.ospadding {
    box-sizing: inherit;
    direction: inherit;
    overflow: auto;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
}
.hXnywk {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 15px;
}
.fBhgXs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 25px;
}
.iPCvVb {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 700;
    color: #0D1136;
}
.kZXmaV {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
    margin: 0 12px;
}
.djStHc {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
    margin-right: 15px;
}
.jDKalT {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
    margin-left: auto;
}

.vNVrf {
    border-top: 1px solid #E6E6E6;
    padding: 20px 15px 0;
    margin-top: 20px;
}
.iZImhr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
}
.kTPYoz {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 400;
    color: #77798C;
}
.kxbXCM {
    font-family: Lato,sans-serif;
    font-size: calc(15px - 1px);
    font-weight: 700;
    color: #0D1136;
}
.bsOIsI {
    margin-top: 25px;
}
.flexrow{
    display: flex;
    flex-direction: column;
}