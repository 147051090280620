.btnHidden {
  display: none;
  margin-left: 10px;
  border-radius: 6px;
  background-color: transparent;
  outline: none;
  background-repeat: no-repeat;
  border: solid 2px green;
  border-radius: 30px;
}
.divBtnHide {
  display: flex;
  flex-direction: row;
}
.btnHidden i {
  color: darkgreen;
  font-size: 14px;
}
.colorred{
  font-weight: bold;
  color: #CC0000;
}
.colorgreen{
  font-weight: bold;
  color: #00CC00;
}