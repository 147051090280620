.marginRight {
    margin: 0px 10px 0px 0px;
  }
  .errorMsg {
    margin-left: 10px;
    color: red;
  }
  
  .inputImage {
    height: 50px;
  }
  .productPrice {
    display: flex;
    flex-direction: row;
  }
  .productPrice input {
    width: 200px;
  }
  