.cardList {
  margin: 5px 5px;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(1, minmax(180px, 1fr));
}
/* @media (max-width: 575px) {
  .cardList { 
    grid-template-columns: auto auto;
  }
} */
@media screen and (min-width: 375px) {
  .cardList {
    grid-template-columns: repeat(2, minmax(180px, 1fr));
  }
}
@media screen and (min-width: 630px) {
  .cardList {
    grid-template-columns: repeat(3, minmax(180px, 1fr));
    margin: 7px 7px;
    grid-gap: 5px;
  }
}
@media screen and (min-width: 900px) {
  .cardList {
    grid-template-columns: repeat(4, minmax(180px, 1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
/* @media screen and (min-width: 1200px){
  .cardList {
    grid-template-columns: repeat(5,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
}
@media screen and (min-width: 1500px){
  .cardList {
    grid-template-columns: repeat(6,minmax(180px,1fr));
    margin: 10px 10px;
    grid-gap: 10px;
  }
} */
.loader {
  color: #009e7f;
  font-size: 14px;
  font-weight: bolder;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 20px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

.deletebtn {
  border:none;
    border-radius: 15px;
    font-size: 15px;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: inherit;
    background-color: #fff;
    color: #d9534f;
    width: 50px;
}