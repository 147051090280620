/* web view */
.eXUcye {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: #ffffff;
    padding: 10px 70px 40px;
}
@media only screen and (min-width: 991px) and (max-width: 1280px){
    .eXUcye {
        padding: 10px 15px 60px;
    }
}
@media only screen and (max-width: 990px){
    .eXUcye {
        padding: 10px 0 60px;
    }
}
.TVBQD {
    width: calc(100% - 330px);
    display: flex;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    margin: auto;
}
@media only screen and (max-width: 1199px){
    .TVBQD {
        width: 100%;
        padding: 0 20px;
    }
}
.duuioQ {
    display: none;
}
@media only screen and (min-width: 990px){
    .duuioQ {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}
.fgelJT {
    width: 330px;
    height: 657px;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid #f1f1f1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 30px;
}
@media only screen and (max-width: 1199px){
    .fgelJT {
        height: 657px;
        width: 310px;
        margin-right: 20px;
    }
}
.kVstku {
    font-family: Poppins,sans-serif;
    font-size: 21px;
    font-weight: 600;
    color: #0D1136;
    margin: 25px 0;
    padding: 0px 20px;
}
.order-scrollbar {
    height: 100%;
    min-height: 420px;
}
.bGLdjy {
    width: 100%;
    padding: 0 20px 20px;
    overflow: auto;
}
.dEBCwJ {
    background-color: #F7F7F7;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex-shrink: 0;
    border: 2px solid transparent;
}
.dEBCwJ.active {
    border: 2px solid #009E7F;
}
.dEBCwJ input[type='radio'] {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}