button:focus {outline:0;}
::-webkit-scrollbar {
    width: 2px;
    height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.jVFuuY {
    
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #009e7f;
    padding: 0;
    border-radius: 6px 0 0 6px;
    box-shadow: 0 21px 36px rgba(0, 0, 0, 0.16);
    border: 0;
    outline: 0;
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 50%;
    margin-top: -46px;
    z-index: 99;
}
.KDupa {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    padding-left: 5px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 10px;
}
.KDupa i {
    margin-right: 10px;
}
.dCrbXJ {
    width: auto;
    height: 35px;
    min-width: 80px;
    overflow: hidden;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #009e7f;
    margin: 0 10px 10px;
}
@media(max-width: 575px) { 
    .jVFuuY {
        top: auto;
        flex-direction: row;
        box-sizing: border-box;
        font-family: Lato, sans-serif;
        margin: 0;
        appearance: none;
        -webkit-box-align: center;
        border-radius: 60px;
        width: calc(100% - 60px);
        height: 45px;
        padding: 2px 2px 2px 30px;
        bottom: 30px;
        right: 30px;
    }
    .KDupa{
        cursor: pointer;
        box-sizing: border-box;
        margin: 0;
        font-family: Lato,sans-serif;
        font-size: 12px;
        padding: 0px;
    }
    .dCrbXJ{
        cursor: pointer;
        box-sizing: border-box;
        margin: 0;
        border-radius: 28px;
        font-family: Lato,sans-serif;
        font-size: 12px;
        width: 90px;
        height: 41px;
        margin-left: auto;
        margin-right: 0;
    }
}
