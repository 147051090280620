.cardContainer {
  /* border: 1px solid gray; */
  position: relative;
  width: 90%;
  color: black; 
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
  transition: 0.3s;display: inline-block;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.25 ease-out;
  margin: 10px;
  height: 180px;
}
  
.cardContainer img {
  object-fit: cover;
  object-position: center;
}
.cardContainer:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

 .text{
  color: rgb(13, 17, 54);

  text-align: center;
  /* text-transform: capitalize; */
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  overFlow: hidden;
  font-size: 18px;
  font-style: bold;
  font-weight: 700;
  font-family: Lato,sans-serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* white-space: nowrap; */
}
@media screen and (max-width: 375px){
  .text {
    font-size: 12px;
  }
}