.boldStyle {
  font-weight: bold;
  font-size: 20px;
}

@media screen {
  #printSection {
    display: none;
  }
  .modal-lg {
    width: 500px;
  }
  #spanDate {
    visibility: hidden;
  }
  #labelDate {
    visibility: hidden;
  }
}

@media print {
  #printSection:after {
    content: "©  " attr(data-date) " Be Smart";
    font-size: 60px;
    font-weight: bold;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  body * {
    font-family: Arial, Helvetica, sans-serif;
    height: auto;
    visibility: hidden;
  }
  .modal-lg {
    max-width: 80% !important;
  }
  .badge {
    font-size: 60px;
  }
  #closeModal {
    visibility: hidden;
    display: none;
  }
  #printModal {
    display: none;
    visibility: hidden;
  }
  th {
    font-size: 60px;
    font-weight: bold;
  }
  td {
    inline-size: 150px;
    overflow-wrap: break-word;
    font-size: 60px;
    font-weight: bold;
  }
  label {
    font-size: 60px;
    font-weight: bold;
  }
  #spanDate {
    visibility: visible;
  }
  #labelDate {
    visibility: visible;
  }
  .boldStyle {
    font-weight: bold;
    font-size: 60px;
  }
  #printSection,
  #printSection * {
    visibility: visible;
  }
  #orderSection {
    display: none;
    visibility: hidden;
  }
}
