.mainDivReg {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  width: 90%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.1rem;
  max-width: 500px;
  margin: 50px auto;
}
.errorMsg {
  margin-left: 10px;
  color: red;
}
.registerBtn {
  background-color: #009e7f;
  color: white;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.formInput {
  width: 100%;
}
.formInput label {
  position: relative;
  display: block;
  width: 100%;
  min-height: 45px;
}
.formInput .placeholder {
  position: absolute;
  display: block;
  top: 23px;
  z-index: 2;
  font-size: 16px;
  transition: all 200ms ease-in-out;
  width: 100%;
  cursor: text;
  color: #c3c3c3;
}
.formInput input,
.formInput textarea {
  position: absolute;
  top: 15px;
  z-index: 1;
  width: 100%;
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid grey;
  transition: border-color 200ms ease-in-out;
  outline: none;
  padding: 0;
  margin: 0;
}
.formInput textarea {
  min-height: 30px;
  padding: 8px 0;
}
.formInput input {
  height: 30px;
}
.formInput input:focus,
.formInput input:valid,
.formInput textarea:focus,
.formInput textarea:valid {
  border-bottom: 2px solid #009e7f;
}
.formInput input:focus + .placeholder,
.formInput input:valid + .placeholder,
.formInput textarea:focus + .placeholder,
.formInput textarea:valid + .placeholder {
  top: 0;
  cursor: inherit;
  font-size: 14px;
  color: #009e7f;
}
