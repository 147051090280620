.innerRndComponent {
    width: 100%;
    padding: 30px;
    height: auto;
    background-color: rgb(247, 248, 249);
    border: 0px;
    box-sizing: border-box;
    border-radius: 2px;
}
.bHlADF {
    font-family: sans-serif;
    font-size: 19px;
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 15px;
}
.deMkW {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 15px;
}
.iapefQ {
    display: block;
    width: 100%;
    padding: 0 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: Lato,sans-serif;
    font-size: 15px;
    line-height: inherit;
    border: 1px solid;
    border-color: #f1f1f1;
    border-radius: 6px;
    background-color: #ffffff;
    color: #0D1136;
    height: 48px;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.iapefQ:hover, .iapefQ:focus {
    outline: 0;
}
.iapefQ:focus {
    border-color: #009E7F;
}
.bUSVGR textarea {
    height: auto;
    min-height: 171px;
    padding-top: 15px;
    resize: none;
}
.dwDYsr {
    width: 100%;
    height: 44px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    color: #ffffff;
    background-color: #009E7F;
    /* -webkit-transition: all 0.3s ease; */
    transition: all 0.3s ease;
    border-radius: 6px;
    /* -webkit-appearance: none; */
    -moz-appearance: none;
    appearance: none;
    /* display: -webkit-box; */
    /* display: -webkit-flex; */
    display: -ms-flexbox;
    display: flex;
    /* -webkit-align-items: center; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    /* -webkit-justify-content: center; */
    -ms-flex-pack: center;
    justify-content: center;
    /* -webkit-flex-shrink: 0; */
    -ms-flex-negative: 0;
    flex-shrink: 0;
    text-align: center;
    /* height: 38px; */
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: inherit;
    border: 0;
}
.reuseModalCloseBtn {
    right: 10px!important;
    background-color: #ffffff!important;
    color: #222222!important;
    border-radius: 15px!important;
    padding: 0 9px!important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.4);
}

.reuseModalCloseBtn {
    position: fixed;
    top: 10px;
    right: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #ffffff;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    z-index: 9999999;
}
.reuseModalCloseBtn i{
    font-weight: 600;
}