.search-box {
  border: none;
  border-bottom: 1px solid #009E7F;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: 18px;
  font-family: Lato,sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #009E7F;
}
@media screen and (min-width: 1200px){
  .search-box {
    line-height: 20px;
    font-size: 16px;
    color: red;
  }
}
.search-box:focus {
  outline: none;
}