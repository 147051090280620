.bOpSCc {
    font-size: 15px;
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom: 1px solid;
    border-bottom-color: #F7F7F7;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.bkLFHu {
    display: -ms-flexbox;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    border-radius: 200px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 30px;
    height: 90px;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    background-color: #F7F7F7;
    color: #0D1136;
}
.cbNtye {
    border: none;
    background-color: transparent;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
    cursor: pointer;
    color: #77798C;
}
.kyNexk {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin: 0 5px;
}
.djMkq {
    font-weight: 700;
    color: #0D1136;
    margin-bottom: 0px;
    line-height: 1.5;
    text-align: center;
}
.ewWbZD {
    color: #009E7F;
    font-size: 12px;
}
.ewWbZD2 {
    color: #0D1136;
    font-size: 14px;
}
.gKpPoA {
    font-size: 13px;
    font-weight: 400;
    color: #77798C;
    margin-bottom: 5px;
}
.cbwArM {
    color: #009E7F;
    margin-left: auto;
    font-size: 12px;
}
.cbwArM2 {
    color: #009E7F;
    margin-left: auto;
    font-size: 14px;
}
.ijpSFz {
    padding: 0px;
    border: 0;
    outline: 0;
    margin-left: 5px;
    cursor: pointer;
    color: rgba(0,0,0,0.25);
    transition: all 0.4s ease;
    background-color: transparent;
}
.ijpSFz:hover {
    color: #ea4d4a;
}
.flexcolumn{
    display: flex;
    flex-direction: column;
    margin: auto;
}
.flexrow{
    display: flex;
    flex-direction: row;
    margin: auto;
}
.margintop2{
    margin-top: 2px;
}
.fFQivv {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
}
.productpercent {
    font-size: 10px;
    font-weight: 400;
    color: #FFB369;
}
.colorred{
    color: red;
}